import { CoreHTMLElement, router } from '../../app.external/core/index.mjs';
import viewScroll from '../../app.services/view/scroll.mjs';

/**
 * Top header component for page navigation.
 */
export default class HTMLOHeader extends CoreHTMLElement {
  constructor() {
    super();

    this.viewScroll = viewScroll;
    this.router = router;

    /** @type {HTMLElement} */
    this.containerRoot = undefined;

    /** @type {HTMLElement} */
    this.viewRoot = undefined;

    /**
     * Indicates if the mobile menu is on.
     * @type {boolean}
     */
    this.is_on = false;

    /**
     * Bind scope.
     */
    this.setColors = this.setColors.bind(this);
    this.close = this.close.bind(this);
  }

  /**
   * Applies a color name on the header.
   * @param {OScrollProps} props
   */
  setColors(props) {
    /**
     * Clear class except for `on`.
     */
    this.classList.forEach(cls => {
      if (cls !== 'on') {
        this.classList.remove(cls);
      }
    });

    if (props?.color) {
      this.classList.add(props.color);

      return;
    }

    if (props.is_scroll_middle) {
      this.classList.add('light');
    }
  }

  setActiveRoute(route) {
    // console.log(route);
  }

  close() {
    this.is_on = false;

    this.setOnByClass(this.is_on, 'on');
    this.setChildOnByClass(this.viewRoot, this.is_on, 'mask');
    this.setChildOnByClass(window.document.body, this.is_on, 'mask');
  }

  toggleMenu() {    
    this.is_on = !this.is_on;

    this.setOnByClass(this.is_on, 'on');
    this.setChildOnByClass(this.viewRoot, this.is_on, 'mask');
    this.setChildOnByClass(window.document.body, this.is_on, 'mask');
  }

  connectedCallback() {
    this.renderElementsByJSON([
      {
        t: 'div',
        r: 'containerRoot',
        a: { class: 'container' },
        c: [
          { 
            t: 'a', 
            a: { class: 'menu far fa-bars', role: 'button', 'aria-label': 'Menu' }, 
            e: { click: this.toggleMenu }
          },
          { 
            t: 'div',
            a: { class: 'logo' },
            c: [
              { 
                t: 'a', 
                a: { href: '/home', 'aria-label': 'Home' },
                e: { click: this.close },
                c: { t: 'img', a: { src: './img/logos/octo-egg-1.svg', alt: 'Octo' }},
              },
              {
                t: 'a', 
                a: { class: 'logo', href: '/home' },
                c: { t: 'b', c: 'Octo' }
              }
            ]
          },
          { 
            t: 'div',
            a: { class: 'navigation' },
            c: [
              { t: 'a', a: { href: '/features' }, i: { route: '/features' }, c: 'Features' },
              { t: 'a', a: { href: '/pricing' }, i: { route: '/pricing' }, c: 'Pricing' },
              { t: 'a', a: { href: '/blog' }, i: { route: '/blog' }, c: 'Blog' },
              { 
                t: 'a',
                a: { class: 'button', href: window.api.editor_domain, rel: 'noopener' },
                c: [
                  'Sign in',
                  { t: 'i', a: { class: 'fa fa-chevron-right' }}
                ]
              }
            ]
          }
        ]
      },
      {
        t: 'div',
        a: { class: 'mobile-container' },
        c: [
          { t: 'a', a: { href: '/features' }, e: { click: this.close }, c: 'Features' },
          { t: 'a', a: { href: '/pricing' }, e: { click: this.close }, c: 'Pricing' },
          { t: 'a', a: { href: '/blog' }, e: { click: this.close }, c: 'Blog' },
          { 
            t: 'a', 
            a: { 
              href: 'https://discord.com/channels/1197421122610806884/1197421195633643530',
              rel: 'noopener',
              target: '_blank'
            }, 
            e: { click: this.close }, 
            c: [
              'Updates ',
              { t: 'i', a: { class: 'far fa-external-link-square' }}
            ]
          },
          { t: 'hr' },
          { t: 'a', a: { href: '/guides' }, e: { click: this.close }, c: 'Guides' },
          { t: 'a', a: { href: '/contact' }, e: { click: this.close }, c: 'Contact' },
        ]
      }
    ], this);

    this.viewRoot = window.document.querySelector('o-view');

    this.viewScroll.watch(this.setColors);
    this.router.watch(this.setActiveRoute);
  }

  disconnectedCallback() {
    this.viewScroll.unwatch(this.setColors);
    this.router.unwatch(this.setActiveRoute);
  }
}

window.customElements.define('o-header', HTMLOHeader);