import keyboard from "../../app.services/keyboard/keyboard.mjs";
import { ControlHTMLElement, numbers } from "../../app.external/core/index.mjs";

export default class ODCDescriptionHTMLElement extends ControlHTMLElement {
  #max_length;

  constructor() {
    super();

    this.keyboard = keyboard;
    this.numbers = numbers;

    /** @type {HTMLTextAreaElement} */
    this.textareaRoot = undefined;
    /** @type {HTMLElement} */
    this.countRoot = undefined;

    /**
     * Specified max length text restriction.
     * @type {number}
     */
    this.#max_length = this.getAttribute('maxlength')
      ? this.numbers.toNumber(this.getAttribute('maxlength')) : 150;

    /**
     * Indicates if the value length should be counted up (false = down). Counting down by default
     * from 150 or maxlength attribute.
     * @type {boolean}
     */
    this.count = this.hasAttribute('count');

    /**
     * Indicates number of rows control occupies.
     */
    this.rows_attribute = this.getAttribute('rows');

    if (this.rows_attribute && !this.rows) {
      this.rows = this.rows_attribute;
    }

    /**
     * Bind scope.
     */
    this.textChange = this.textChange.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
  }

  set maxlength(max_length) {
    this.#max_length = this.numbers.toNumber(max_length);
  }

  renderValue() {
    if (this.textareaRoot && this.countRoot) {
      this.textareaRoot.value = this.value || '';

      this.setCount();
    }
  }

  setCount() {
    if (this.count) {
      const count = this.value ? this.value.length : 0;

      this.renderElementByJSON(count.toString(), this.countRoot);
    } else {
      const count = this.value ? this.#max_length - this.value.length : this.#max_length;

      this.renderElementByJSON(count.toString(), this.countRoot);
    }
  }

  connectedCallback() {
    this.renderElementsByJSON([
      {
        t: 'textarea',
        r: 'textareaRoot',
        a: {
          placeholder: this.placeholder,
          rows: this.rows || 4,
          'aria-label': this.aria || '',
          name: this.prop
        },
        e: [
          { input: this.textChange },
          { focus: this.focus },
          { blur: this.blur }
        ]
      },
      { t: 'span', r: 'countRoot' }
    ], this);

    if (!this.count) {
      this.textareaRoot.setAttribute('maxlength', this.#max_length.toString());
    }

    this.setCount();
    this.postConnected();
  }

  postDisconnect() {
    this.textareaRoot.removeEventListener('input', this.textChange);
    this.textareaRoot.removeEventListener('focus', this.focus);
    this.textareaRoot.removeEventListener('blur', this.blur);
  }

  textChange() {
    if (this.textareaRoot) {
      this.setValue(this.textareaRoot.value);
    }
  }

  focus() {
    this.keyboard.suspend();
  }

  blur() {
    this.keyboard.resume();
  }
}

window.customElements.define('o-description', ODCDescriptionHTMLElement);