const contact = {
  name: 'contact',
  title: 'Contact',
  route: '/contact',
  type: 'main',
  view_class: null,
  view: [
    {
      t: 'o-hero',
      a: { class: 'short right' },
      i: { scroll_amount: 60, color: 'dark-pink' },
      c: [
        { t: 'o-greeblie-group', i: { position: 'above', color_1: '#ff5794', color_2: '#D20065' }},
        { t: 'o-greeblie-group', i: { position: 'below', color_1: '#ff5794', color_2: '#D20065' }},
        {
          t: 'div',
          a: { class: 'content' },
          c: { 
            t: 'div', 
            a: { class: 'hero-block' }, 
            c: [
              { t: 'h1', c: 'Contact' }, 
              { t: 'p', c: 'What\'s on your mind?' }
            ] 
          }
        }
      ]
    },
    {
      t: 'div',
      a: { class: 'block light top-more-offset bottom-half-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content one-forth' },
          c: [
            {
              t: 'section',
              a: { class: 'side-navigation' },
              c: [
                {
                  t: 'a',
                  a: { href: '/features' },
                  c: [
                    'Discover features',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                },
                {
                  t: 'a',
                  a: { href: '/guides' },
                  c: [
                    'Explore guides',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                },
                {
                  t: 'a',
                  a: { href: '/pricing#faqs' },
                  c: [
                    'Frequently asked questions',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'form',
              c: [
                { t: 'p', c: 'Fill out the form below to report a bug or inquire about anything.' },
                {
                  t: 'div',
                  a: { class: 'form' },
                  c: [
                    { t: 'p', a: { class: 'required' }, c: 'Your Email' },
                    {
                      t: 'o-email',
                      i: {
                        prop: 'email',
                        service: 'manageMessage',
                        placeholder: 'email@company.com'
                      }
                    },
                    { t: 'p', c: 'Topic' },
                    {
                      t: 'o-list',
                      i: {
                        prop: 'topic',
                        service: 'manageMessage',
                        list: 'topic_list',
                        optionlabel: 'name',
                        optionid: 'value',
                        default: '-'
                      }
                    },
                    { t: 'p', c: 'Platform' },
                    {
                      t: 'o-list',
                      i: {
                        prop: 'platform',
                        service: 'manageMessage',
                        list: 'platform_list',
                        optionlabel: 'name',
                        optionid: 'value',
                        default: '-'
                      }
                    },
                    { t: 'p', a: { class: 'required' }, c: 'Subject' },
                    {
                      t: 'o-text',
                      i: {
                        prop: 'subject',
                        service: 'manageMessage',
                        placeholder: 'What can we help you with?'
                      }
                    },
                    { t: 'p', a: { class: 'required' }, c: 'Message' },
                    {
                      t: 'o-description',
                      i: {
                        prop: 'message',
                        service: 'manageMessage',
                        placeholder: 'Your message...'
                      }
                    }
                  ]
                },
                {
                  t: 'div',
                  a: { class: 'controls' },
                  c: [
                    {
                      t: 'o-async-button',
                      i: {
                        service: 'manageMessage',
                        set: 'send',
                        label: 'Send message',
                        validate: 'validate'
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      t: 'div',
      a: { class: 'block dark top-bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half tight' },
          c: [
            {
              t: 'section',
              a: { class: 'summary very-tight' },
              c: [
                { t: 'i', a: { class: 'fal fa-search large' } },
                { t: 'h3', c: 'Octo updates' },
                {
                  t: 'p',
                  c: [
                    'Explore the latest features and stay up-to-date by checking out the ',
                    {
                      t: 'a',
                      a: {
                        class: 'static',
                        href: 'https://discord.com/channels/1197421122610806884/1197421195633643530',
                        rel: 'noopener',
                        target: '_blank'
                      },
                      c: [
                        'updates ',
                        { t: 'i', a: { class: 'far fa-external-link-square' }}
                      ]
                    },
                    ' for more information.'
                  ]
                }
              ]
            },
            {
              t: 'section',
              a: { class: 'summary very-tight' },
              c: [
                { t: 'i', a: { class: 'fal fa-envelope-open large' }},
                { t: 'h3', c: 'Need additional information?' },
                {
                  t: 'p',
                  c: [
                    'For any inquiries, whether it\'s support, sales, or extra information, feel free to reach out to us at ',
                    { t: 'a', a: { href: 'mailto:support@octo.coffee' }, c: 'support@octo.coffee' },
                    '.'
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default contact;