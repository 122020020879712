import { CoreHTMLElement } from '../../app.external/core/index.mjs';
import rest from '../../app.services/api/rest.mjs';

export default class HTMLOAppDownload extends CoreHTMLElement {
  constructor() {
    super();

    this.rest = rest;

    /** @type {HTMLLinkElement}} */
    this.aRoot = undefined;

    if (!Object.prototype.hasOwnProperty.call(this, 'platform')) {
      /**
       * Name of the platform to retrieve.
       * @type {string}
       */
      this.platform = this.getAttribute('platform');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'os')) {
      /**
       * Name of the os to retrieve.
       * @type {string}
       */
      this.os = this.getAttribute('os');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'icon')) {
      /**
       * Name of the os to retrieve.
       * @type {string}
       */
      this.icon = this.getAttribute('icon');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'download_path')) {
      /**
       * Path for the download.
       * @type {string}
       */
      this.download_path = this.getAttribute('download-path');
    }

    this.toOS = {
      'ipad': 'iPad',
      'macos': 'macOS',
      "windows": 'Windows',
      "android": 'Android'
    }
  }

  async getLink() {
    const result = await this.rest.getVersion(this.platform?.toLowerCase(), this.os?.toLowerCase());

    if (result?.length) {
      this.aRoot.href = result[0].link;
    }
  }

  connectedCallback() {
    this.renderElementByJSON({
      t: 'a',
      a: { href: this.download_path, download: 'Octo' },
      c: [
        { t: 'i', a: { class: this.icon }},
        { t: 'span', c: `Download for ${this.toOS[this.os]}` }
      ],
      r: 'aRoot'
    }, this);

    this.getLink();
  }
}

window.customElements.define('o-app-download', HTMLOAppDownload);