import { CoreHTMLElement, text } from '../../app.external/core/index.mjs';

/**
 * For displaying portions of the application.
 */
export default class HTMLOFigure extends CoreHTMLElement {
  constructor() {
    super();

    this.text = text;

    /** @type {HTMLElement} */
    this.figureRoot = undefined;

    if (!Object.prototype.hasOwnProperty.call(this, 'aspect')) {
      /**
       * Aspect ratio of the figure.
       * @type {string}
       */
      this.aspect = this.getAttribute('aspect');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'frame')) {
      /**
       * Name of the frame.
       * @type {string}
       */
      this.frame = this.getAttribute('frame');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'has_limit')) {
      /**
       * Indicates if the figure has a limit.
       * @type {boolean}
       */
      this.has_limit = this.hasAttribute('limit');
    }
  }

  renderBlank() {
    this.renderElementByJSON({
      t: 'div', r: 'figureRoot', a: { class: 'figure' }, h: this.innerHTML
    }, this);
  }

  renderBrowser() {
    this.renderElementsByJSON([
      { 
        t: 'div', 
        a: { class: 'header' },
        c: [
          { 
            t: 'div', 
            a: { class: 'menu' } ,
            c: [
              { t: 'div', a: { class: 'dot' }},
              { t: 'div', a: { class: 'dot' }},
              { t: 'div', a: { class: 'dot' }}
            ]
          },
          { t: 'div', a: { class: 'search' }}
        ]
      },
      { t: 'div', r: 'figureRoot', a: { class: 'figure' }, h: this.innerHTML }
    ], this);
  }

  renderIPad() {
    this.renderElementsByJSON([
      { t: 'div', a: { class: 'volume' }},
      { t: 'div', r: 'figureRoot', a: { class: 'figure' }, h: this.innerHTML }
    ], this);
  }

  renderMacOs() {
    this.renderElementsByJSON([
      { 
        t: 'div', 
        a: { class: 'header' },
        c: { 
          t: 'div', 
          a: { class: 'menu' },
          c: [
            { t: 'div', a: { class: 'dot' }},
            { t: 'div', a: { class: 'dot' }},
            { t: 'div', a: { class: 'dot' }}
          ]
        }
      },
      { t: 'div', r: 'figureRoot', a: { class: 'figure' }, h: this.innerHTML }
    ], this);
  }

  renderWindows() {
    this.renderElementsByJSON([
      { 
        t: 'div', 
        a: { class: 'header' },
        c: [
          { t: 'div', a: { class: 'navigation' }},
          { 
            t: 'div', 
            a: { class: 'menu' },
            c: [
              { t: 'div', a: { class: 'fal fa-minus' }},
              { t: 'div', a: { class: 'fal fa-square' }},
              { t: 'div', a: { class: 'fal fa-times' }}
            ]
          }
        ]
      },
      { t: 'div', r: 'figureRoot', a: { class: 'figure' }, h: this.innerHTML }
    ], this);
  }

  renderAndroid() {
    this.renderElementByJSON({
      t: 'div', r: 'figureRoot', a: { class: 'figure' }, h: this.innerHTML
    }, this);
  }

  connectedCallback() {
    if (this.frame === 'blank') {
      this.renderBlank();
    }

    if (this.frame === 'browser') {
      this.renderBrowser();
    }

    if (this.frame === 'ipad') {
      this.renderIPad();
    }

    if (this.frame === 'macos') {
      this.renderMacOs();
    }

    if (this.frame === 'windows') {
      this.renderIPad();
    }

    if (this.frame === 'android') {
      this.renderAndroid();
    }

    this.classList.add(this.frame);
    this.classList.add(this.aspect);

    this.setAttribute('role', 'img');

    if (this.has_limit) {
      this.classList.add('limit');
    }

    if (this.figureRoot) {
      this.figureRoot.dispatchEvent(new CustomEvent('rendered', { bubbles: true, cancelable: true }));
    }
  }
}

window.customElements.define('o-figure', HTMLOFigure);