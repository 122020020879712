const company = {
  name: 'company',
  title: 'Company',
  route: '/company',
  type: 'main',
  view_class: null,
  view: [
    {
      t: 'o-hero',
      a: { class: 'medium right' },
      i: { scroll_amount: 60, color: 'pale-orange' },
      c: [
        { t: 'o-greeblie-group', i: { position: 'above', color_1: '#9900ff', color_2: '#ff5794' }},
        { t: 'o-greeblie-group', i: { position: 'below', color_1: '#9900ff', color_2: '#ff5794' }},
        {
          t: 'div',
          a: { class: 'content' },
          c: {
            t: 'div',
            a: { class: 'hero-block' },
            c: [
              { t: 'h1', c: 'Company' },
              { t: 'p', c: 'Technology is evolving and so should your editor' }
            ]
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light top-more-offset bottom-half-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content' },
          c: {
            t: 'section',
            a: { class: 'feature center dark' },
            c: [
              {
                t: 'h2',
                a: { class: 'pale-orange' },
                c: [
                  { t: 'span', a: { class: 'pink' }, c: 'Octo' },
                  ' began with a simple ',
                  { t: 'span', a: { class: 'pink' }, c: 'idea' }
                ]
              },
              {
                t: 'p',
                c: 'The desire to design an entire UI/UX interface seamlessly within a single editor.'
              },
              { t: 'i', a: { class: 'fal fa-lightbulb offset-top large' } },
              { t: 'i', a: { class: 'fal fa-arrow-down large' } }
            ]
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark top-half-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane very-tight' },
              c: [
                { t: 'i', a: { class: 'fal fa-bezier-curve orange large' }},
                { t: 'h3', c: 'Vector and raster editing in one' },
                {
                  t: 'p',
                  c: 'So, we set out to build an editor that lets users design interfaces with vectors, create icons and logos from scratch, and handle raster editing—all in a single, web-based application, without the need to switch between tools.'
                },
                { 
                  t: 'p', 
                  c: 'We believed this would create a much better day-to-day experience while keeping costs down.' 
                }
              ]
            },
            {
              t: 'section',
              a: { class: 'plane very-tight' },
              c: [
                { t: 'i', a: { class: 'fal fa-raygun orange large' } },
                { t: 'h3', c: [ 'Animation and prototyping' ] },
                {
                  t: 'p',
                  c: 'To support a complete workflow, animations and UX prototyping were needed, along with the ability to preview the entire design.'
                },
                {
                  t: 'p',
                  c: 'From the start, code generation was included to ensure smooth handoff and to make sure the final design was implemented accurately, without any misinterpretation.'
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'center pale-orange-half half' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'four-three' },
            c: { 
              t: 'o-editor', 
              a: { scale: '0.6', animation: 'test', config: 'observatoryEditor' } 
            }
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light top-bottom-offset bottom-quarter-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content' },
          c: [
            {
              t: 'section',
              a: { class: 'feature center' },
              c: [
                { t: 'h3', a: { class: 'pale-orange' }, c: 'Meet the team' },
                {
                  t: 'p',
                  c: 'Our team, composed of real actual people, is dedicated to creating only the very best next generation design tools.'
                }
              ]
            },
            {
              t: 'div',
              a: { class: 'tiles' },
              c: [
                {
                  t: 'div',
                  a: { class: 'tile' },
                  c: [
                    {
                      t: 'o-image',
                      a: {
                        path: 'https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e'
                      }
                    },
                    { t: 'p', c: 'Design' }
                  ]
                },
                {
                  t: 'div',
                  a: { class: 'tile' },
                  c: [
                    {
                      t: 'o-image',
                      a: {
                        path: 'https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e'
                      }
                    },
                    { t: 'p', c: 'Engineering' }
                  ]
                },
                {
                  t: 'div',
                  a: { class: 'tile' },
                  c: [
                    {
                      t: 'o-image',
                      a: {
                        path: 'https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e'
                      }
                    },
                    { t: 'p', c: 'Operations' }
                  ]
                },
                {
                  t: 'div',
                  a: { class: 'tile' },
                  c: [
                    {
                      t: 'o-image',
                      a: {
                        path: 'https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e'
                      }
                    },
                    { t: 'p', c: 'Sales' }
                  ]
                },
                {
                  t: 'div',
                  a: { class: 'tile' },
                  c: [
                    {
                      t: 'o-image',
                      a: {
                        path: 'https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e'
                      }
                    },
                    { t: 'p', c: 'Project Management' }
                  ]
                },
                {
                  t: 'div',
                  a: { class: 'tile' },
                  c: [
                    {
                      t: 'o-image',
                      a: {
                        path: 'https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e'
                      }
                    },
                    { t: 'p', c: 'Quality Engineering' }
                  ]
                },
                {
                  t: 'div',
                  a: { class: 'tile' },
                  c: [
                    {
                      t: 'o-image',
                      a: {
                        path: 'https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e'
                      }
                    },
                    { t: 'p', c: 'Customer Success' }
                  ]
                },
                {
                  t: 'div',
                  a: { class: 'tile' },
                  c: [
                    {
                      t: 'o-image',
                      a: {
                        path: 'https://s3.amazonaws.com/octo.coffee/marketing/9bc55efe-b7f0-4270-bef6-558ec1f5795f.jpeg%3Fcolor%3D%236e6b6e'
                      }
                    },
                    { t: 'p', c: 'UI/UX' }
                  ]
                }
              ]
            },
            {
              t: 'section',
              a: { class: 'plane center' },
              c: { t: 'p', c: 'Let\'s say we believe in small teams 😉' }
            }
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light-figure top-bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content' },
          c: {
            t: 'figure',
            a: { class: 'pale-orange inset center middle' },
            c: {
              t: 'div',
              a: { class: 'feature' },
              c: [
                { t: 'h3', c: 'Press Information' },
                {
                  t: 'p',
                  c: [
                    'Want to know more about Octo? Reach us at ',
                    {
                      t: 'a',
                      a: { href: 'mailto:press@octo.coffee' },
                      c: 'press@octo.coffee'
                    }
                  ]
                }
              ]
            }
          }
        }
      ]
    }
  ]
};

export default company;