import { CoreHTMLElement, coreGenerate } from "../../app.external/core/index.mjs";

/**
 * Visual element.
 */
export default class HTMLOGreeblie extends CoreHTMLElement {
  constructor() {
    super();

    this.coreGenerate = coreGenerate;

    this.gradient_id = this.coreGenerate.uuidv4();

    if (!Object.prototype.hasOwnProperty.call(this, 'color')) {
      /**
       * Name of the solid color.
       * @type {string}
       */
      this.color = this.getAttribute('color');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'color_1')) {
      /**
       * Name of the color of the gradient.
       * @type {string}
       */
      this.color_1 = this.getAttribute('color-1');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'color_2')) {
      /**
       * Name of the color of the gradient.
       * @type {string}
       */
      this.color_2 = this.getAttribute('color-2');
    }

    /**
     * Size of the element.
     * @type {string}
     */
    if (!Object.prototype.hasOwnProperty.call(this, 'size')) {
      this.size = this.getAttribute('size');
    }

    /**
     * Side of the element to be placed.
     * @type {string}
     */
    if (!Object.prototype.hasOwnProperty.call(this, 'side')) {
      this.side = this.getAttribute('side');
    }

    /**
     * Side of the element to be placed.
     * @type {boolean}
     */
    if (!Object.prototype.hasOwnProperty.call(this, 'invert')) {
      this.invert = this.hasAttribute('invert');
    }

    /**
     * Side of the element to be placed.
     * @type {boolean}
     */
    if (!Object.prototype.hasOwnProperty.call(this, 'below')) {
      this.below = this.hasAttribute('below');
    }
  }

  renderGreeblie() {
    const size = this.size || 'very-large';
    const side = this.side || 'left';
    const color = this.color || '';
    const invert = this.invert ? 'invert' : '';
    const below = this.below ? 'below' : '';

    this.renderElementByJSON({
      t: 'svg',
      a: { 
        class: `greeblie ${size} ${side} ${color} ${invert} ${below}`, 
        viewBox: "0 0 400 400", 
        xmlns:"http://www.w3.org/2000/svg" 
      },
      c: [
        this.color_1 && this.color_2 ? { 
          t: 'defs',
          c: { 
            t: 'linearGradient', 
            a: { x1: "0%", y1: "0%", x2: "100%", y2: "0%", id: this.gradient_id },
            c: [
              { t: 'stop', a: { offset: "0%", 'stop-color': this.color_1 }},
              { t: 'stop', a: { offset: "100%", 'stop-color': this.color_2 }}
            ]
          }
        } : undefined,
        { 
          t: 'path', 
          a: { 
            d: 'M400,400H0c82.2,0,120.7-84,200-84S297.5,400,400,400z', 
            fill: this.color_1 && this.color_2 ? `url(#${this.gradient_id})` : ''
          }
        }
      ]
    }, this);
  }

  connectedCallback() {
    this.renderGreeblie();
  }
}

window.customElements.define('o-greeblie', HTMLOGreeblie);