/**
 * Main confirmation controller for displaying global messages from async transactions and
 * editor instructions.
 */
export class Confirmation {
  constructor() {
    this.watchFns = [];
  }

  /**
   * @param {OConfirmationWatchFn} fn
   */
  watch(fn) {
    this.watchFns.push(fn);
  }

  /**
   * @param {OConfirmationWatchFn} fn
   */
  unwatch(fn) {
    for (const [index, watchFn] of this.watchFns.entries()) {
      if (watchFn === fn) {
        this.watchFns.splice(index, 1);
      }
    }
  }

  /**
   * @param {OptionConfirmation} options
   */
  trigger(options) {
    for (const fn of this.watchFns) {
      fn(options);
    }
  }

  /**
   * Modifies result to fit confirmation decision format.
   * @param {OCombinedConfirmation} result
   */
  mutateResult (result) {
    return {
      message: `Undo ${result?.user?.display_name ?
        result.user.display_name : result?.user?.full_name} edit?`,
      icon: 'fa-undo',
      is_error: false
    };
  }

  /**
   * Displays confirmation with optional decision controls.
   * @param {OCombinedConfirmation} result
   * @param {boolean} [is_decision]
   * @param {*} [accept_fn] Supports decision confirmation.
   * @param {boolean} [sticky] Determines if confirmation is sticky.
   */
  open(result, is_decision, accept_fn, sticky) {
    const derived_result = is_decision ? this.mutateResult(result) : result;

    this.trigger({
      result: derived_result,
      is_decision,
      accept: accept_fn,
      sticky
    });
  }

  /**
   * Displays error confirmation only if there is an error, otherwise runs the function.
   * @param {*} result
   * @param {() => {}} [fn]
   */
  isError(result, fn) {
    if (result?.is_error) {
      this.trigger(result);
    } else if(fn) {
      fn();
    }
  }

  /**
   * Dismisses any open confirmation applicable when leaving a section.
   */
  dismiss() {
    this.trigger({ dismiss: true });
  }
}

export default new Confirmation();