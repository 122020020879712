import { ControlHTMLElement } from '../../app.external/core/index.mjs';
import keyboard from "../../app.services/keyboard/keyboard.mjs";

export default class ODCEmailHTMLElement extends ControlHTMLElement {
  constructor() {
    super();

    this.keyboard = keyboard;

    /** @type {HTMLInputElement} */
    this.inputRoot = undefined;

    this.is_input_selected = false;

    /**
     * Bind scope.
     */
    this.textChange = this.textChange.bind(this);
    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.select = this.select.bind(this);
  }

  renderValue() {
    if (this.inputRoot) {
      this.inputRoot.value = this.value || '';
    }
  }

  connectedCallback() {
    this.renderElementByJSON({
      t: 'input',
      a: { 
        type: 'email', 
        'aria-label': this.aria, 
        placeholder: this.placeholder, 
        name: this.prop 
      },
      r: 'inputRoot',
      e: [
        { input: this.textChange }, 
        { focus: this.focus }, 
        { blur: this.blur }
      ]
    }, this);

    this.addEventListener('click', this.select);
    this.postConnected();
  }

  postDisconnect() {
    this.removeEventListener('click', this.select);
    this.inputRoot.removeEventListener('input', this.textChange);
    this.inputRoot.removeEventListener('focus', this.focus);
    this.inputRoot.removeEventListener('blur', this.blur);
  }

  textChange() {
    if (this.inputRoot?.value !== this.value) {
      this.setValue(this.inputRoot.value);
    }
  }

  select() {
    if (!this.is_input_selected) {
      this.inputRoot.select();
    }

    this.is_input_selected = true;
  }

  focus() {
    this.keyboard.suspend();
  }

  blur() {
    this.keyboard.resume();
    this.is_input_selected = false;
  }
}

window.customElements.define('o-email', ODCEmailHTMLElement);