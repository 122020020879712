import ManagerBase  from './manager-base.mjs';
import queryBilling from '../api/queries/billing.mjs';
import { serviceManager } from '../../app.external/core/index.mjs';

class ManageBilling extends ManagerBase  {
  constructor() {
    super();

    this.queryBilling = queryBilling;

    this.props = {
      filter: 'year'
    };
  }

  async load() {
    this.props.plansAndFeatures = await this.queryBilling.getPlansAndFeatures('USER');

    this.trigger();
  }
}

const manageBilling = new ManageBilling();

export default manageBilling;

serviceManager.set('manageBilling', manageBilling);