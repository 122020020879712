import { CoreHTMLElement, coreDate } from '../../app.external/core/index.mjs';
import manageBlog from '../../app.services/managers/blog.mjs';

export default class HTMLOPosts extends CoreHTMLElement {
  constructor() {
    super();

    this.manageBlog = manageBlog;
    this.coreDate = coreDate;

    /** @type {HTMLElement} */
    this.root = undefined;
    /** @type {HTMLElement} */
    this.postsRoot = undefined;

    /**
     * Bind scope.
     */
    this.render = this.render.bind(this);
  }

  render(posts) {
    if (posts?.raw?.length) {
      this.renderElementByJSON({
        t: 'div',
        a: { class: 'blog' },
        r: 'postsRoot'
      }, this.root);

      for (const post of posts.raw) {
        if (post.is_active) {
          const node = this.renderElementByJSON({
            t: 'div',
            a: { class: 'card' },
            c: {
              t: 'a',
              a: { href: post.link, rel: 'noopener', target: '_blank' },
              c: [
                { 
                  t: 'span', 
                  a: { style: `background-color: ${post.primary_color};` },
                  c: { 
                    t: 'o-image',
                    a: { path: post.cover, alt: post.title }
                  }
                },
                {
                  t: 'div',
                  c: [
                    { 
                      t: 'p', 
                      a: { class: 'category', style: `color: ${post.accent_color};` }, 
                      c: post.category 
                    },
                    { t: 'p', a: { class: 'title' }, c: post.title },
                    { t: 'p', a: { class: 'date' }, c: this.coreDate.formatDate(post.created_at) }
                  ]
                }
              ]
            }
          });

          this.postsRoot.append(node);
        }
      }
    } else {
      this.renderEmpty();
    }
  }

  renderLoading() {
    this.renderElementByJSON({
      t: 'div',
      a: { class: 'blog' },
      c: [
        { t: 'div', a: { class: 'loading' }},
        { t: 'div', a: { class: 'loading' }},
        { t: 'div', a: { class: 'loading' }},
        { t: 'div', a: { class: 'loading' }},
        { t: 'div', a: { class: 'loading' }},
        { t: 'div', a: { class: 'loading' }}
      ]
    }, this.root);
  }

  renderEmpty() {
    this.renderElementByJSON({
      t: 'div',
      a: { class: 'empty' },
      c: [
        { t: 'i', a: { class: 'fal fa-ufo-beam' }},
        { t: 'p', c: 'Nothing here' }
      ]
    }, this.root);
  }

  connectedCallback() {
    this.renderElementByJSON({
      t: 'o-block',
      a: { class: 'light bottom-half-offset top-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content' },
          r: 'root'
        }
      ]
    }, this);

    this.renderLoading();

    this.manageBlog.watch(this.render);
    this.manageBlog.getPosts();
  }

  disconnectedCallback() {
    this.manageBlog.unwatch(this.render);
  }  
}

window.customElements.define('o-posts', HTMLOPosts);