const pricing = {
  name: 'pricing',
  title: 'Pricing',
  route: '/pricing',
  type: 'main',
  view_class: null,
  view: [
    {
      t: 'o-hero',
      a: { class: 'short right' },
      i: { scroll_amount: 60, color: 'green' },
      c: [
        { t: 'o-greeblie-group', i: { position: 'above', color_1: '#0099ff', color_2: '#00B8D2' }},
        { t: 'o-greeblie-group', i: { position: 'below-home', color_1: '#0099ff', color_2: '#00B8D2' }},
        {
          t: 'div',
          a: { class: 'content' },
          c: {
            t: 'div',
            a: { class: 'hero-block' },
            c: [
              { t: 'h1', c: 'Pricing' },
              { t: 'p', c: 'Start building for free' }
            ]
          }
        }
      ]
    },
    {
      t: 'div',
      a: { class: 'block light top-more-offset bottom-half-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content' },
          c: [
            {
              t: 'section',
              a: { class: 'feature center dark' },
              c: [
                {
                  t: 'h2',
                  a: { class: 'pale-green' },
                  c: [
                    'Octo is ',
                    { t: 'span', a: { class: 'blue' }, c: 'free' },
                    ' to use for a limited time!'
                  ]
                },
                {
                  t: 'p',
                  c: 'Try it out and let us know what you think.'
                },
                {
                  t: 'a',
                  a: { class: 'large', href: window.api.editor_domain, rel: 'noopener' },
                  c: [
                    'Try Octo for free',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      t: 'div',
      a: { class: 'block dark-figure top-bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content' },
          c: {
            t: 'figure',
            a: { class: 'dark large-inset' },
            c: {
              t: 'div',
              a: { class: 'feature' },
              c: [
                {
                  t: 'h3',
                  a: { class: 'pale-orange', id: 'faqs' },
                  c: 'Frequently asked questions'
                },
                {
                  t: 'o-accordion',
                  i: { heading: 'Can I try Octo before paying?' },
                  c: { 
                    t: 'p', 
                    c: 'Yes! You can use Octo\'s full suite of features for free with public designs.' 
                  }
                },
                {
                  t: 'o-accordion',
                  i: { heading: 'Can I cancel my plan at any time?' },
                  c: {
                    t: 'p',
                    c: 'Yes, you can cancel your subscription at any time through the app. All subscriptions will be canceled as of next billing cycle and your plan will be downgraded to a free plan. You will still have access to view and export your existing projects.'
                  }
                },
                {
                  t: 'o-accordion',
                  i: { heading: 'What is the refund policy?' },
                  c: { 
                    t: 'p', 
                    c: [ 
                      'Read about our policy in the ', 
                      { t: 'a', a: { href: '/terms' }, c: 'Terms of Use' }, 
                      ' page.' 
                    ] 
                  }
                }
              ]
            }
          }
        }
      ]
    }
  ]
};

export default pricing;