import ManagerBase  from './manager-base.mjs';
import queryPost from '../api/queries/post.mjs';
import { serviceManager } from '../../app.external/core/index.mjs';

class ManageBlog extends ManagerBase  {
  constructor() {
    super();

    this.queryPost = queryPost;
  }

  async getPosts() {
    this.props = {
      sort: 'name',
      reverse: false,
      layout: false
    };
    this.props.raw = await this.queryPost.getPosts();

    this.trigger();
  }
}

const manageBlog = new ManageBlog();

export default manageBlog;

serviceManager.set('manageBlog', manageBlog);