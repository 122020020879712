import { Observer } from '../../app.external/core/index.mjs';

/**
 * Service manager from DOM scrolling.
 */
export class ViewScroll extends Observer {
  constructor() {
    super();

    /**
     * @type {OScrollProps}
     */
    this.props = {
      is_scroll_above: false,
      is_scroll_below: false,
      is_scroll_middle: false,
      is_scroll_over: false,
      is_scroll_top: false
    };

    this.observed = 'props';
  }

  /**
   * @param {OScrollProps} props
   */
  paintOn(props) {
    this.props = props;

    this.trigger();
  }

  /**
   * @param {OScrollProps} props
   */
  paintOff(props) {
    this.props = props;

    this.trigger();
  }
}

export default new ViewScroll();