class View {
  constructor() {
    this.ignore = null;
    this.close = null;
  }

  /**
   * @param {ElementDOM} element
   */
  processElement(element) {
    this.ignore = element[0];
  }

  bindClick(event) {
    if (this.ignore && !this.ignore.contains(event.target)) {
      this.close?.();

      this.removeClickToClose();
    }
  }

  /**
   * @param {ElementDOM} element
   * @param {CallbackFn} fn
   */
  addClickToClose(element, fn) {
    if (this.close) {
      this.close();
      this.removeClickToClose();

      return;
    }

    this.processElement(element);
    this.close = fn;

    window.addEventListener('click', event => this.bindClick(event));
  }

  removeClickToClose() {
    this.ignore = null;
    this.close = null;

    window.removeEventListener('click', event => this.bindClick(event));
  }
}

const view = new View();

export default view;