import { CoreHTMLElement } from '../../app.external/core/index.mjs';

/**
 * For grouping greeblie and background gradient in the hero element.
 */
export default class HTMLOGreeblie extends CoreHTMLElement {
  constructor() {
    super();

    if (!Object.prototype.hasOwnProperty.call(this, 'color_1')) {
      /**
       * Name of the color of the gradient.
       * @type {string}
       */
      this.color_1 = this.getAttribute('color-1');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'color_2')) {
      /**
       * Name of the color of the gradient.
       * @type {string}
       */
      this.color_2 = this.getAttribute('color-2');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'position')) {
      /**
       * Name of the position.
       * @type {string}
       */
      this.position = this.getAttribute('position');
    }
  }

  renderAbove() {
    this.renderElementByJSON({
      t: 'div',
      a: { class: 'greeblies above' },
      c: { t: 'o-greeblie', i: { color_1: this.color_1, color_2: this.color_2, invert: true }}
    }, this);
  }

  renderBelow() {
    this.renderElementByJSON({
      t: 'div',
      a: { class: 'mask' },
      c: [
        {
          t: 'o-greeblie',
          i: { color_1: this.color_1, color_2: this.color_2, size: 'large', side: 'left' }
        },
        { t: 'o-greeblie', i: { side: 'left', color: 'dark', below: true }},
        {
          t: 'o-greeblie',
          i: { color_1: this.color_1, color_2: this.color_2, size: 'medium', side: 'right' }
        },
        { t: 'o-greeblie', i: { size: 'medium', side: 'right', color: 'dark', below: true }}
      ]
    }, this);
  }

  renderHomeBelow() {
    this.renderElementByJSON({
      t: 'div',
      a: { class: 'mask' },
      c: [
        {
          t: 'o-greeblie',
          i: { color_1: this.color_1, color_2: this.color_2, size: 'large', side: 'right' }
        },
        { t: 'o-greeblie', i: { side: 'right', color: 'dark', below: true }}
      ]
    }, this);
  }

  renderBelowDevice() {
    this.renderElementByJSON({
      t: 'div',
      a: { class: 'mask' },
      c: [
        {
          t: 'o-greeblie',
          i: { color_1: this.color_1, color_2: this.color_2, size: 'large', side: 'left' }
        },
        { 
          t: 'o-greeblie', 
          i: { side: 'left', color: 'dark', below: true, invert: true }
        }
      ]
    }, this);
  }

  connectedCallback() {
    if (this.position === 'above') {
      this.renderAbove();
    }

    if (this.position === 'below') {
      this.renderBelow();
    }

    if (this.position === 'below-home') {
      this.renderHomeBelow();
    }

    if (this.position === 'below-device') {
      this.renderBelowDevice();
    }
  }
}

window.customElements.define('o-greeblie-group', HTMLOGreeblie);