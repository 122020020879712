import ManagerBase  from './manager-base.mjs';
import queryUpdate from '../api/queries/update.mjs';
import { serviceManager } from '../../app.external/core/index.mjs';

class ManageUpdates extends ManagerBase  {
  constructor() {
    super();

    this.queryUpdate = queryUpdate;
  }

  async getUpdates() {
    this.props = {
      sort: 'name',
      reverse: false,
      layout: false
    };
    this.props.raw = await this.queryUpdate.getUpdates();

    this.trigger();
  }
}

const manageUpdates = new ManageUpdates();

export default manageUpdates;

serviceManager.set('manageUpdates', manageUpdates);