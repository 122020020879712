const privacy = {
  name: 'privacy',
  title: 'Privacy',
  route: '/privacy',
  type: 'main',
  view_class: null,
  view: {
    t: 'div',
    c: [
      {
        t: 'o-hero',
        a: { class: 'short right' },
        i: { scroll_amount: 30, color: 'blue' },
        c: { t: 'o-greeblie-group', i: { position: 'below-home', color_1: '#040D21', color_2: '#0C2D6B' }},
      },
      {
        t: 'o-block',
        a: { class: 'light' },
        c: [
          { t: 'o-mask' },
          {
            t: 'div',
            a: { class: 'content above' },
            c: {
              t: 'div',
              a: { class: 'legal' },
              c: {
                t: 'div',
                a: { class: 'content' },
                c: [
                  { t: 'h1', c: 'Privacy Policy for Octo LLC' },
                  { 
                    t: 'p', 
                    c: [
                      { t: 'b', c: 'Last Updated: ' },
                      'Dec 12, 2024'
                    ]
                  },
                  { t: 'p', c: 'Thank you for using Octo LLC\'s graphic editor software (the “Software”). Your privacy is important to us, and this Privacy Policy explains how we collect, use, and safeguard your information when you use our Software and services.' },
                  { t: 'p', c: 'By using the Software, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.' },
                  { t: 'hr' },
                  { t: 'h2', c: '1. Introduction' },
                  { t: 'p', c: 'Octo LLC (“we,” “us,” or “our”) operates a graphic editor software designed for individuals and businesses. This Privacy Policy describes how we handle your data, including any personal and usage information, in compliance with applicable U.S. privacy laws.' },
                  { t: 'hr' },
                  { t: 'h2', c: '2. Information We Collect' },
                  { t: 'p', c: 'We collect the following types of information:' },
                  { t: 'h3', c: '2.1 Personal Information' },
                  { t: 'p', c: 'When you create an account or interact with the Software, we collect:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Full Name' },
                      { t: 'li', c: 'Email Address' },
                      { 
                        t: 'li', 
                        c: [
                          'Payment Information (processed securely via ',
                          { t: 'b', c: 'Stripe' },
                          '; we do not store payment details ourselves).'
                        ]
                      }
                    ]
                  },
                  { t: 'h3', c: '2.2 Files and Content' },
                  { 
                    t: 'ol',
                    c: [
                      { 
                        t: 'li', 
                        c: [
                          'Files, projects, and designs created or uploaded by you are stored securely on ',
                          { t: 'b', c: 'AWS servers' },
                          ' located in the United States.'
                        ]
                      },
                      { t: 'li', c: 'Collaborative features allow sharing of projects with other users; shared access is controlled by you.' },
                    ]
                  },
                  { t: 'h3', c: '2.3 Usage Data' },
                  { t: 'p', c: 'We automatically collect data about how you use the Software, such as:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Device type and operating system (e.g., Windows, macOS, or Linux)' },
                      { t: 'li', c: 'App usage behavior (e.g., features used, session duration)' },
                      { 
                        t: 'li', 
                        c: [
                          'Crash logs and error data from ',
                          { t: 'b', c: 'internal logs' },
                          ' (future integration with tools like Sentry is planned).'
                        ]
                      }
                    ]
                  },
                  { 
                    t: 'p', 
                    c: [
                      'This data is collected using ',
                      { t: 'b', c: 'Google Analytics' },
                      ' to help us improve the Software.'
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '3. How We Use Your Information' },
                  { t: 'p', c: 'We use the collected information for the following purposes:' },
                  {
                    t: 'ol',
                    c: [
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'To Provide and Improve the Software: ' },
                          'Ensure functionality, track performance, and improve features.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Account Management: ' },
                          'Create and manage your account and billing.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Error and Defect Management: ' },
                          'Monitor and fix bugs using internal logs.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Future Communications: ' },
                          'Notify you about updates, new features, or send promotional emails (optional, with opt-out capability).' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Security and Fraud Prevention: ' },
                          'Protect against unauthorized access, fraud, or misuse.'
                        ] 
                      }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '4. Sharing of Information' },
                  { t: 'p', c: 'We do not sell your data. However, we may share it under the following circumstances:' },
                  { 
                    t: 'ol',
                    c: [
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Service Providers: ' },
                          'We share limited information with trusted third-party providers:' 
                        ]
                      },
                      {
                        t: 'ol',
                        c: [
                          { 
                            t: 'li', 
                            c: [
                              { t: 'b', c: 'Stripe ' },
                              'for payment processing.' 
                            ]
                          },
                          { 
                            t: 'li', 
                            c: [
                              { t: 'b', c: 'AWS ' },
                              'for file storage.' 
                            ]
                          },
                          { 
                            t: 'li', 
                            c: [
                              { t: 'b', c: 'Google Analytics ' },
                              'for usage tracking.' 
                            ]
                          }
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Legal Compliance: ' },
                          'If required to comply with U.S. laws, regulations, or legal proceedings.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Collaborative Features: ' },
                          'Data you choose to share with other users within the Software.'
                        ]
                      }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '5. Data Storage and Security' },
                  { 
                    t: 'ol', 
                    c: [
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Location: ' },
                          'All data (personal, usage, and design files) is stored on ',
                          { t: 'b', c: 'AWS servers ' },
                          ' and ',
                          { t: 'b', c: 'MongoDB databases ' },
                          'in the United States.'
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Security Measures: ' },
                          'We implement encryption, firewalls, and access controls to protect your data.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Data Retention: ' },
                          'Your information is retained as long as your account remains active. Upon deletion, all associated data (including files) will be removed from our systems within a reasonable timeframe.' 
                        ]
                      }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '6. Your Rights' },
                  { t: 'p', c: 'As a user, you have the following rights:' },
                  { 
                    t: 'ol',
                    c: [
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Access Your Data: ' },
                          'Request access to all personal information and design files.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Delete Your Data: ' },
                          'You can delete your account and associated files at any time through account settings or by contacting us.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Retrieve Your Data: ' },
                          'Download all your design files and projects.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Future Opt-Out: ' },
                          'Opt out of receiving promotional emails when this feature becomes available.' 
                        ]
                      }
                    ] 
                  },
                  { 
                    t: 'p', 
                    c: [
                      'To exercise any of these rights, contact us at: ',
                      { t: 'a', a: { mailto: 'support@octo.coffee' }, c: 'support@octo.coffee' },
                      '.'
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '7. Third-Party Integrations' },
                  { t: 'p', c: 'In the future, the Software may integrate third-party services such as:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Stock image libraries' },
                      { 
                        t: 'li', 
                        c: [
                          'Error reporting tools like ',
                          { t: 'b', c: 'Sentry' }
                        ]
                      }
                    ]
                  },
                  { t: 'p', c: 'These tools will be covered by their respective privacy policies, and we will update this section accordingly.' },
                  { t: 'hr' },
                  { t: 'h2', c: '8. Cookies and Tracking Technologies' },
                  { 
                    t: 'p', 
                    c: [
                      'The Software uses ',
                      { t: 'b', c: 'Google Analytics ' },
                      'to collect usage data via cookies and similar tracking technologies. Cookies help us analyze how you use the Software to improve performance. You can disable cookies through your browser settings.'
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '9. Children\'s Privacy' },
                  { t: 'p', c: 'The Software is intended for adults only (18 years or older). We do not knowingly collect information from children under 18.' },
                  { t: 'hr' },
                  { t: 'h2', c: '10. Changes to This Privacy Policy' },
                  { t: 'p', c: 'We may update this Privacy Policy from time to time to reflect changes in features, practices, or legal requirements. If we make significant updates, we will notify you via email or through the Software.' },
                  { 
                    t: 'p', 
                    c: [
                      { t: 'b', c: 'Last Updated: ' },
                      'Dec 12, 2024' 
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '11. Contact Information' },
                  { 
                    t: 'p', 
                    c: [
                      'For questions about this Privacy Policy or your data, please contact us at: ',
                      { t: 'a', a: { mailto: 'support@octo.coffee' }, c: 'support@octo.coffee' }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'p', c: 'This Privacy Policy is effective as of Dec 12, 2024.' }
                ]
              }
            }
          }
        ]
      }
    ]
  }
};

export default privacy;