const home = {
  name: 'home',
  title: '',
  route: '/',
  type: 'main',
  view_class: null,
  view: [
    {
      t: 'o-hero',
      a: { class: 'center tall' },
      i: { scroll_amount: 60, color: 'blue' },
      c: [
        { t: 'o-greeblie-group', i: { position: 'above', color_1: '#040D21', color_2: '#0C2D6B' }},
        { t: 'o-greeblie-group', i: { position: 'below-home', color_1: '#040D21', color_2: '#0C2D6B' }},
        {
          t: 'div',
          a: { class: 'content' },
          c: [
            {
              t: 'div',
              a: { class: 'hero-block main center' },
              c: [
                {
                  t: 'h1',
                  c: [
                    'This is the ',
                    { t: 'span', a: { class: 'dark-blue' }, c: 'editor ' },
                    'you want'
                  ]
                },
                {
                  t: 'p',
                  c: 'Unleash your creativity with lightning-fast delivery of breathtaking collaborative designs'
                },
                {
                  t: 'a',
                  a: { href: window.api.editor_domain, rel: 'noopener' },
                  c: [
                    'Try Octo for free',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'div',
              a: { class: 'hero-figure' },
              c: {
                t: 'o-figure',
                i: { frame: 'browser', aspect: 'four-three' },
                c: {
                  t: 'o-editor',
                  a: { scale: '0.6', animation: 'test', config: 'observatoryEditor' }
                }
              }
            }
          ]
        }
      ]
    },
    { t: 'o-block', i: { type: 'top-empty' }},
    {
      t: 'o-block',
      a: { class: 'dark skew very-tall above bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane' },
            c: [
              { t: 'h2', c: 'Put your clients at the heart of the design process' },
              { t: 'p', c: 'Let your clients make design adjustments without breaking your design systems by providing curated component variations and style presets.'},
              { t: 'p', c: 'Allow for quick revisions with design versioning and element annotation.'},
              {
                t: 'ul',
                a: { class: 'section-list blue' },
                c: [
                  { t: 'li', c: { t: 'o-check', i: { content: 'Lockable components' }}},
                  { t: 'li', c: { t: 'o-check', i: { content: 'Element annotation and comments' }}},
                  { t: 'li', c: { t: 'o-check', i: { content: 'Design versioning and history' }}},
                  { t: 'li', c: { t: 'o-check', i: { content: 'Live prototyping' }}}
                ]
              },
              {
                t: 'a',
                a: { href: 'guides/#components' },
                c: [
                  'Learn how to include your clients',
                  { t: 'i', a: { class: 'fa fa-chevron-right' }}
                ]
              }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right right-inset' },
          c: {
            t: 'div',
            a: { class: 'pieces' },
            c: [
              {
                t: 'div',
                a: { class: 'column' },
                c: [
                  { t: 'div', a: { class: 'blank two-three orange' } },
                  { t: 'div', a: { class: 'blank five-two pale-green' } }
                ]
              },
              {
                t: 'div', 
                a: { class: 'column' },
                c: [
                  { t: 'div', a: { class: 'blank three-two blue' }},
                  { t: 'div', a: { class: 'blank three-two pink' }},
                  { t: 'div', a: { class: 'blank three-two purple' }}
                ]
              },
              {
                t: 'div',
                a: { class: 'column' },
                c: { t: 'div', a: { class: 'blank two-three dark-blue' }}
              }
            ]
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', c: 'All-in-one vector and image editing' },
              { t: 'p', c: 'Stay in your flow. Design icons, logos, raster image edits, and other assets—all without switching tools or leaving your editor.' },
              { t: 'p', c: 'Create complex vector graphics with full SVG support, using tools to combine, subtract, and refine shapes.' },
              {
                t: 'ul',
                a: { class: 'section-list orange' },
                c: [
                  { t: 'li', c: { t: 'o-check', i: { content: 'Vector editing' }}},
                  { t: 'li', c: { t: 'o-check', i: { content: 'Full SVG support' }}},
                  { t: 'li', c: { t: 'o-check', i: { content: 'Raster image editing and refinement' }}},
                ]
              },
              {
                t: 'a',
                a: { class: 'orange', href: '/guides#realtime-vector-editing' },
                c: [
                  'Learn more about vector and raster editing',
                  { t: 'i', a: { class: 'fa fa-chevron-right' }}
                ]
              }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right inset pale-orange top-bottom-offset' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'three-two', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.8' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', c: 'Real-time collaborative design' },
              { t: 'p', c: 'Work in sync with your team in a single document. With live collaborative design, you can build, prototype, and refine your work together as it happens.' },
              { t: 'p', c: 'Add comments and annotate specific elements directly on your designs. Provide clear, contextual feedback, reducing the need for lengthy back-and-forths.' },
              {
                t: 'ul',
                a: { class: 'section-list purple' },
                c: [
                  { t: 'li', c: { t: 'o-check', i: { content: 'Live design' }}},
                  { t: 'li', c: { t: 'o-check', i: { content: 'Interactive interface prototyping' }}},
                  { t: 'li', c: { t: 'o-check', i: { content: 'Comments and element annotation' }}},
                  { t: 'li', c: { t: 'o-check', i: { content: 'Live code view' }}}
                ]
              },
              {
                t: 'a',
                a: { class: 'purple', href: '/guides#design-versioning' },
                c: [
                  'Learn more about real-time design',
                  { t: 'i', a: { class: 'fa fa-chevron-right' }}
                ]
              }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right inset purple half-offset' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'three-two', limit: '' },
            c: { t: 'o-projects', a: { scale: '0.8' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark top-half-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Deliver designs continuously with versions' },
                { t: 'p', c: 'Hand off designs without disrupting your team\'s productivity. Construct prototypes independently from your primary design workflow, ensuring a smooth creative process.' },
                {
                  t: 'ul',
                  a: { class: 'section-list pale-green' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Design versioning' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Document history' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'pale-green', href: '/guides#code-generation' },
                  c: [
                    'Learn more about design versioning',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Save time with automation' },
                { t: 'p', c: 'Take advantage of tools like dynamic style guide generation and code handoff to keep your design in sync across versions.' },
                {
                  t: 'ul',
                  a: { class: 'section-list pale-green' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Dynamic style guide' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Code generation and handoff' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Event process mapping' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'pale-green', href: '/guides#code-generation' },
                  c: [
                    'Learn more about automation',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'center pale-green-half half' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'four-three' },
            c: { 
              t: 'o-editor', 
              a: { scale: '0.6', animation: 'test', config: 'observatoryEditor' } 
            }
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light top-offset bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane tight' },
              c: [
                { t: 'h2', c: 'Teams and organizations' },
                { t: 'p', c: 'Build clear, organized teams with defined roles and manage your projects using organizations.' },
                { t: 'p', c: 'Share components and other design elements like fonts, images, icons, and presets across projects, ensuring consistency without reimplementation.' },
                {
                  t: 'ul',
                  a: { class: 'section-list pale-green' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Private organizations' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Role-based permissions' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Privately shared assets' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'pale-green', href: '/features' },
                  c: [
                    'Learn more about organizations',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'figure',
              a: { class: 'large-inset pale-green' },
              c: {
                t: 'o-figure',
                i: { frame: 'blank', aspect: 'four-three' },
                c: {
                  t: 'o-projects',
                  a: {
                    scale: '0.5',
                    drawer: '',
                    animation: 'marketingProjects'
                  }
                }
              }
            }
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'skew top-quarter-offset' },
      i: { color: 'dark-blue' },
      c: [
        {
          t: 'div',
          a: { class: 'mask' },
          c: [
            {
              t: 'div',
              a: { class: 'lines' },
              c: [
                { t: 'div' },
                { t: 'div' },
                { t: 'div' },
                { t: 'div' }
              ]
            },
            {
              t: 'div',
              a: { class: 'greeblies blue' },
              c: { t: 'o-greeblie', i: { size: 'very-large', side: 'left', color: 'blue', invert: true }}          
            },
            { t: 'div', a: { class: 'line-greeblie' }}
          ]
        },
        {
          t: 'div',
          a: { class: 'content' },
          c: {
            t: 'section',
            a: { class: 'feature center middle' },
            c: [
              { t: 'h3', a: { class: 'purple' }, c: 'Build It All Here!' },
              { t: 'p', c: 'Create, collaborate, and deliver—all in one place.' },
              {
                t: 'a',
                a: { href: window.api.editor_domain, rel: 'noopener' },
                c: [
                  'Get started for free',
                  { t: 'i', a: { class: 'fa fa-chevron-right' } }
                ]
              }
            ]
          }
        }
      ]
    },
    { t: 'div', a: { class: 'block light' }, c: { t: 'o-mask' }}
  ]
};

export default home;