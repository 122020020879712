import { CoreHTMLElement } from "../../app.external/core/index.mjs";

/**
 * Check mark with description.
 */
export default class HTMLOCheck extends CoreHTMLElement {
  constructor() {
    super();

    if (!Object.prototype.hasOwnProperty.call(this, 'content')) {
      /**
       * Description.
       * @type {string}
       */
      this.content = this.getAttribute('content');
    }
  }

  connectedCallback() {
    this.renderElementsByJSON([
      { t: 'i', a: { class: 'fa fa-check-circle' }},
      { t: 'p', c: this.content }
    ], this);
  }
}

window.customElements.define('o-check', HTMLOCheck);