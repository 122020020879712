import Scroll from '../core/scroll.mjs';
import { numbers } from '../../app.external/core/index.mjs';

/**
 * Top page hero element.
 */
export default class HTMLOHero extends Scroll {
  constructor() {
    super();

    this.numbers = numbers;

    /**
     * Name of the background color.
     * @type {string}
     */
    if (!Object.prototype.hasOwnProperty.call(this, 'color')) {
      this.color = this.getAttribute('color');
    }

    /**
     * Scroll amount.
     * @type {string}
     */
    if (!Object.prototype.hasOwnProperty.call(this, 'scroll_amount')) {
      this.scroll_amount = this.getAttribute('scroll');
    }
  }

  connectedCallback() {
    const scroll = this.numbers.toNumber(this.scroll_amount);
    
    if (scroll) {
      this.watchScroll(scroll);
    }

    if (this.color) {
      this.classList.add(this.color);
    }
  }

  disconnectedCallback() {
    this.unwatchScroll();
  }
}

window.customElements.define('o-hero', HTMLOHero);