import { CoreHTMLElement, coreDate } from '../../app.external/core/index.mjs';
import manageBilling from '../../app.services/managers/billing.mjs';

export default class HTMLOFeatures extends CoreHTMLElement {
  constructor() {
    super();

    this.coreDate = coreDate;
    this.manageBilling = manageBilling;

    /**
     * Bind scope.
     */
    this.render = this.render.bind(this);
  }

  render() {
   
  }

  connectedCallback() {
    this.renderElementByJSON({
      t: 'div',
      a: { class: 'pricing-features' },
      c: [
        { t: 'div', a: { class: 'header' } },
        { t: 'div', a: { class: 'features' } }
      ]
    }, this);

    this.manageBilling.watch(this.render);
    this.manageBilling.load();
  }

  disconnectedCallback() {
    this.manageBilling.unwatch(this.render);
  }  
}

window.customElements.define('o-pricing-features', HTMLOFeatures);

{/* <div class="pricing-features" ng-show="plans.length">
  <div class="header" ng-class="display()">
    <h2>Features</h2>
    <div ng-repeat="plan in plans | limitTo: 3 | orderBy: 'order' track by plan._id">
      <a
        href="{{getPath(plan._id, plan.price.recurring.interval)}}"
        rel="noopener">
        <span class="info">
          <p ng-if="plan.price">
            <span>${{plan.price.unit_amount / 100 | number: 2}}</span>
            <span class="unit">/ {{plan.price.recurring.interval}}</span>
          </p>
          <p ng-if="!plan.price">Free</p>
          <h3
            ng-style="{ 'color': plan.primary_color ? plan.primary_color : plan.accent_color }">
            Select {{plan.name}}
            <i class="fa fa-chevron-right"></i>
          </h3>
        </span>
      </a>
    </div>
  </div>
  <div class="features" ng-class="display()" ng-repeat="feature in combined_features">
    <span>
      <h3>{{feature.name}}</h3>
      <p>{{feature.short_description}}</p>
    </span>
    <div ng-repeat="plan in plans">
      <i
        class="far"
        ng-style="{ 'color': getColor(getFeature(plan.features, feature.name), plan) }"
        ng-class="{
          'fa-check': getFeature(plan.features, feature.name) === true,
          'fa-times': getFeature(plan.features, feature.name) === false
        }"></i>
    </div>
  </div>
</div> */}