export default class HTMLOGradient extends HTMLElement {
  constructor() {
    super();

    if (!Object.prototype.hasOwnProperty.call(this, 'color_1')) {
      /**
       * Name of the color of the gradient.
       * @type {string}
       */
      this.color_1 = this.getAttribute('color-1');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'color_2')) {
      /**
       * Name of the color of the gradient.
       * @type {string}
       */
      this.color_2 = this.getAttribute('color-2');
    }
  }

  connectedCallback() {
    if (this.color_1 && this.color_2) {
      this.style.background = `linear-gradient(45deg, ${this.color_1} 0%, ${this.color_2} 100%)`;
    }
  }
}

window.customElements.define('o-gradient', HTMLOGradient);