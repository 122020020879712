const terms = {
  name: 'terms',
  title: 'Terms',
  route: '/terms',
  type: 'main',
  view_class: null,
  view: {
    t: 'div',
    c: [
      {
        t: 'o-hero',
        a: { class: 'short right' },
        i: { scroll_amount: 30, color: 'blue' },
        c: { t: 'o-greeblie-group', i: { position: 'below-home', color_1: '#040D21', color_2: '#0C2D6B' }},
      },
      {
        t: 'o-block',
        a: { class: 'light' },
        c: [
          { t: 'o-mask' },
          {
            t: 'div',
            a: { class: 'content above' },
            c: {
              t: 'div',
              a: { class: 'legal' },
              c: {
                t: 'div',
                a: { class: 'content' },
                c: [
                  { t: 'h1', c: 'Terms of Service for Octo LLC' },
                  { 
                    t: 'p', 
                    c: [
                      { t: 'b', c: 'Last Updated: ' },
                      'Dec 12, 2024'
                    ]
                  },
                  { t: 'p', c: 'Welcome to Octo LLC\'s graphic editor software (the “Software”). These Terms of Service (“Terms”) govern your access to and use of the Software and services provided by Octo LLC (“we,” “us,” or “our”).' },
                  { t: 'p', c: 'By accessing or using the Software, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, you may not use the Software.' },
                  { t: 'hr' },
                  { t: 'h2', c: '1. Acceptance of Terms' },
                  { t: 'p', c: 'By creating an account, accessing, or using the Software, you agree to these Terms, our Privacy Policy, and any additional policies we may implement. You are responsible for ensuring compliance with all applicable laws.' },
                  { t: 'hr' },
                  { t: 'h2', c: '2. Accounts and Registration' },
                  { t: 'h3', c: '2.1 Account Creation' },
                  { t: 'p', c: 'To access the Software, you must:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Be at least 18 years old.' },
                      { t: 'li', c: 'Create an account by providing your full name, email address, and other required information.' }
                    ]
                  },
                  { t: 'p', c: 'You are responsible for:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Maintaining the confidentiality of your account credentials.' },
                      { t: 'li', c: 'All activities that occur under your account.' }
                    ]
                  },
                  { t: 'h3', c: '2.2 Account Termination' },
                  { t: 'p', c: 'We reserve the right to suspend or terminate your account if:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'You violate these Terms.' },
                      { t: 'li', c: 'Your account is inactive for an extended period.' },
                      { t: 'li', c: 'We suspect fraudulent activity.' }
                    ]
                  },
                  { t: 'p', c: 'You may delete your account at any time, after which all associated data and files will be permanently deleted.' },
                  { t: 'hr' },
                  { t: 'h2', c: '3. User Responsibilities' },
                  { t: 'p', c: 'By using the Software, you agree:' },
                  { 
                    t: 'ol',
                    c: [
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Not to misuse the Software ' },
                          'for any illegal, harmful, or unauthorized purposes.' 
                        ]
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'Not to upload or share prohibited content' },
                          ', including but not limited to malware, copyrighted material without permission, or offensive content.'
                        ] 
                      },
                      { 
                        t: 'li', 
                        c: [
                          { t: 'b', c: 'To collaborate responsibly ' },
                          'when sharing design files with other users.' 
                        ]
                      }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '4. Ownership and Licensing' },
                  { t: 'h3', c: '4.1 Your Content' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'You retain ownership of all files, designs, and content you create or upload using the Software.' },
                      { t: 'li', c: 'By using the Software, you grant Octo LLC a limited license to store, process, and display your content to provide the Software\'s features.' }
                    ]
                  },
                  { t: 'h3', c: '4.2 Software Ownership' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'The Software, including its features, interface, and all related intellectual property, is owned by Octo LLC.' },
                      { t: 'li', c: 'You are granted a limited, non-exclusive, non-transferable license to use the Software for personal or business purposes.' }
                    ]
                  },
                  { t: 'p', c: 'You may not:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Copy, modify, or distribute the Software.' },
                      { t: 'li', c: 'Reverse engineer or attempt to extract the Software\'s source code.' }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '5. Payments and Subscriptions' },
                  { t: 'h3', c: '5.1 Payment Terms' },
                  { 
                    t: 'ol',
                    c: [
                      { 
                        t: 'li', 
                        c: [
                          'Payments are processed securely through ',
                          { t: 'b', c: 'Stripe' },
                          '.'
                        ]
                      },
                      { t: 'li', c: 'By subscribing to a paid plan, you agree to provide accurate billing information and authorize us to charge recurring payments.' }
                    ]
                  },
                  { t: 'h3', c: '5.2 Refund Policy' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Refunds are only issued in exceptional cases at our sole discretion.' },
                      { t: 'li', c: 'You may cancel your subscription at any time, but no partial refunds will be issued for unused time.' }
                    ]
                  },
                  { t: 'hr', },
                  { t: 'h2', c: '6. Collaborative Features' },
                  { t: 'p', c: 'The Software allows you to share and collaborate on design files with other users. You are responsible for managing file permissions and ensuring you do not share sensitive or unauthorized content.' },
                  { t: 'hr' },
                  { t: 'h2', c: '7. Prohibited Activities' },
                  { t: 'p', c: 'You agree not to:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Use the Software for illegal or unauthorized purposes.' },
                      { t: 'li', c: 'Upload or share content that is offensive, abusive, or violates intellectual property rights.' },
                      { t: 'li', c: 'Attempt to disrupt or interfere with the Software\'s operation.' }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '8. Disclaimers and Limitation of Liability' },
                  { t: 'h3', c: '8.1 Disclaimers' },
                  { t: 'p', c: 'The Software is provided “as is” and “as available,” without warranties of any kind, express or implied. We do not guarantee that:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'The Software will be error-free, uninterrupted, or secure.' },
                      { t: 'li', c: 'Your content will not be lost or damaged.' }
                    ]
                  },
                  { t: 'h3', c: '8.2 Limitation of Liability' },
                  { t: 'p', c: 'To the maximum extent permitted by law, Octo LLC will not be liable for any:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Indirect, incidental, or consequential damages.' },
                      { t: 'li', c: 'Loss of files, profits, or data resulting from your use of the Software.' }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '9. Indemnification' },
                  { t: 'p', c: 'You agree to indemnify, defend, and hold harmless Octo LLC, its affiliates, and employees from any claims, damages, or losses resulting from:' },
                  { 
                    t: 'ol',
                    c: [
                      { t: 'li', c: 'Your violation of these Terms.' },
                      { t: 'li', c: 'Your misuse of the Software.' }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'h2', c: '10. Modifications to the Software and Terms' },
                  { t: 'p', c: 'We reserve the right to update, modify, or discontinue the Software or these Terms at any time. Changes will be effective immediately upon posting. Continued use of the Software constitutes acceptance of the updated Terms.' },
                  { t: 'hr' },
                  { t: 'h2', c: '11. Governing Law' },
                  { t: 'p', c: 'These Terms are governed by the laws of the State of Nevada, without regard to its conflict of laws principles. Any disputes will be resolved in the courts located in Reno, Nevada.' },
                  { t: 'hr' },
                  { t: 'h2', c: '12. Contact Information' },
                  { 
                    t: 'p', 
                    c: [
                      'If you have any questions or concerns about these Terms, please contact us at: ',
                      { t: 'a', a: { mailto: 'support@octo.coffee' }, c: 'support@octo.coffee' }
                    ]
                  },
                  { t: 'hr' },
                  { t: 'p', c: 'By using the Software, you acknowledge that you have read, understood, and agreed to these Terms of Service.' }
                ]
              }
            }
          }
        ]
      }
    ]
  }
};

export default terms;