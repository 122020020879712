import ManagerBase  from './manager-base.mjs';
import queryMessage from '../api/queries/message.mjs';
import { serviceManager, text } from '../../app.external/core/index.mjs';
import confirmation from '../confirmation/confirmation.mjs';

class ManageMessage extends ManagerBase  {
  constructor() {
    super();

    this.queryMessage = queryMessage;
    this.text = text;
    this.confirmation = confirmation;
  }

  async send() {
    const result = await this.queryMessage.addMessage(this.props);

    this.clear();
    this.trigger();

    this.confirmation.open(result);

    return result;
  }

  /**
   * Validates message input.
   */
  validate() {
    if (this.text.isEmail(this.props.email) && this.text.hasText(this.props.subject) 
      && this.text.hasText(this.props.message)) {

      return true;
    }

    return false;
  }
}

const manageMessage = new ManageMessage();

export default manageMessage;

serviceManager.set('manageMessage', manageMessage);