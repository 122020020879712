import viewText from '../core/text.mjs';
import storeAuth from '../store/queries/auth.mjs';

/**
 * @class GraphQl
 */
export class GraphQl {
  constructor() {
    this.path = `${window.api.api}/v1/`;
    this.bridge = '/bridge/v1/';
    this.storeAuth = storeAuth;
    this.viewText = viewText;
  }

  /**
   * Send payload to graphql endpoint.
   * @param {string} query
   * @param {*} [variables]
   */
  async fetch(query, variables) {
    const res = await fetch(this.path, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ query, variables })
    }).catch(error => {
      return { is_error: true, message: this.viewText.endWith(error.message, '.') };
    });

    // @ts-ignore
    if (res.is_error) {
      return res;
    }

    // @ts-ignore
    const json = await res.json();

    if (json.data?.errors) {
      return { is_error: true };
    }

    const key = Object.keys(json.data)[0];

    return json ? json.data[key] : null;
  }

  /**
   * Reduces the object to only include required fields.
   * Currently supports only one level array filter.
   * @param {Array<string | object>} fields
   * @param {*} input
   */
  pruneInput(fields, input) {
    const clean = {};

    if (!input) {
      return clean;
    }

    for (const prop of fields) {
      if (prop in input && typeof prop === 'string') {
        clean[prop] = input[prop];
      } else {
        const key = Object.keys(prop)[0];
        const props = prop[key];
        const sub_inputs = input[key];

        if (sub_inputs?.length) {
          clean[key] = [];

          for (const sub_input of sub_inputs) {
            const sub_clean = Object.keys(sub_input)
              .filter(key => props.includes(key))
              .reduce((obj, key) => {
                obj[key] = sub_input[key];

                return obj;
              }, {});

            clean[key].push(sub_clean);
          }
        }
      }
    }

    return clean;
  }
}

const graphQl = new GraphQl();

export default graphQl;
