import path from '../../app/path.mjs';

export class Rest {
  constructor() {
    this.path = path;
  }

  /**
   * Returns apps version by platform and OS.
   */
  async getVersion(platform, os) {
    const path = `apps/${platform}/${os}`;
    const result = await this.fetch(path);

    return result;
  }

  async fetch(path) {
    const res = await fetch(this.path.origin + '/' + path, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    }).catch(error => {
      return { is_error: true, message: error };
    });

    // @ts-ignore
    const json = typeof res?.json === 'function' ? await res.json() : res;

    return json;
  }
}

export default new Rest();