import Scroll from '../core/scroll.mjs';
import { numbers, text } from '../../app.external/core/index.mjs';

export default class HTMLOBlock extends Scroll {
  constructor() {
    super();

    this.numbers = numbers;
    this.text = text;
    
    if (!Object.prototype.hasOwnProperty.call(this, 'scroll_amount')) {
      /**
       * Scroll amount.
       * @type {string}
       */
      this.scroll_amount = this.getAttribute('scroll');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'size')) {
      /**
       * Size.
       * @type {string}
       */
      this.size = this.getAttribute('size');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'color')) {
      /**
       * Color.
       * @type {string}
       */
      this.color = this.getAttribute('color');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'offset')) {
      /**
       * Offset.
       * @type {string}
       */
      this.offset = this.getAttribute('offset');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'has_skew')) {
      /**
       * Determines if the block has skew.
       * @type {boolean}
       */
      this.has_skew = this.hasAttribute('skew');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'type')) {
      /**
       * Block type. 
       * @type {string}
       */
      this.type = this.getAttribute('type');
    }
  }

  renderEmpty() {
    this.renderElementByJSON({ t: 'o-mask' }, this);

    this.classList.add('dark');
    this.classList.add('tall');
  }

  connectedCallback() {
    const scroll = this.numbers.toNumber(this.scroll_amount);

    if (scroll) {
      this.watchScroll(scroll);
    }

    if (this.size) {
      this.classList.add(this.size);
    }

    if (this.color) {
      this.classList.add(this.color);
    }

    if (this.offset) {
      this.classList.add(`${this.offset}-offset`);
    }

    if (this.has_skew) {
      this.classList.add('skew');
    }

    /**
     * Empty block for spacing.
     */
    if (this.type === 'top-empty') {
      this.renderEmpty();
    }
  }

  disconnectedCallback() {
    this.unwatchScroll();
  }
}

window.customElements.define('o-block', HTMLOBlock);