const features = {
  name: 'features',
  title: 'Features',
  route: '/features',
  type: 'main',
  view_class: null,
  view: [
    {
      t: 'o-hero',
      a: { class: 'short right' },
      i: { scroll_amount: 60, color: 'dark-purple' }, 
      c: [
        { t: 'o-greeblie-group', i: { position: 'above', color_1: '#0088DD', color_2: '#9900FF' }},
        { t: 'o-greeblie-group', i: { position: 'below', color_1: '#0088DD', color_2: '#9900FF' }},
        {
          t: 'div',
          a: { class: 'content' },
          c: {
            t: 'div',
            a: { class: 'hero-block' },
            c: [
              { t: 'h1', c: 'Features' },
              { t: 'p', c: 'Here are some of the things' }
            ]
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark top-more-offset bottom-offset skew above' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Real-time collaborative prototyping' },
                { t: 'p', c: 'Work together in the same document, live and in real time. Prototype, build, review, and deliver designs as a team—everything happens seamlessly, all in one place.' },
                {
                  t: 'ul',
                  a: { class: 'section-list blue' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Live design' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Interactive interface prototyping' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Comments and element annotation' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Live code view' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { href: '/features' },
                  c: [
                    'Learn more about collaborative prototyping',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'figure',
              a: { class: 'blue large-inset' },
              c: {
                t: 'o-figure',
                i: { frame: 'browser' },
                c: { t: 'o-editor', a: { scale: '0.5' }}
              }
            }
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane top-half-offset' },
              c: [
                { t: 'h2', c: 'Vector editing' },
                { t: 'p', c: 'Build interface mockups with tools made for UI layouts. Use vector tools to combine and refine shapes to make icons and logos.' },
                { t: 'p', c: 'Achieve pixel-perfect designs with precision alignment tools, and save time with reusable symbols and components that keep your work consistent across projects.' },
                {
                  t: 'ul',
                  a: { class: 'section-list purple' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Interface mockup' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Full SVG support' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Vector boolean operations' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Precision alignment and positioning' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Reusable symbols and components' }}},
                    
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'purple', href: '/guides#realtime-vector-editing' },
                  c: [
                    'Learn more about vector editing',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'side right inset purple top-bottom-offset' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'three-two', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.8' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane top-half-offset' },
              c: [
                { t: 'h2', c: 'Raster editing' },
                { t: 'p', c: 'Easily crop, resize, and retouch images to adjust dimensions and ensure they fit your designs.' },
                { t: 'p', c: 'Fine-tune colors and use masking tools for precise edits that blend into your work.' },
                {
                  t: 'ul',
                  a: { class: 'section-list pale-green' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Image cropping and resizing' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Retouching tools' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Color correction tools' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Selection and masking tools' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'pale-green', href: '/guides#realtime-vector-editing' },
                  c: [
                    'Learn more about raster editing',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'side right inset pale-green half-offset' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'three-two', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.8' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark top-bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half reverse' },
          c: [
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Component generation' },
                { t: 'p', c: 'Component generation allows you to quickly create reusable design elements, like buttons, cards, and form fields, that can be applied across your project.' },
                { t: 'p', c: 'Customize and tweak components without breaking their structure, ensuring design consistency and efficiency.' },
                {
                  t: 'a',
                  a: { class: 'blue', href: '/features' },
                  c: [
                    'Learn more about creating components',
                    { t: 'i', a: { class: 'fa fa-chevron-right' } }
                  ]
                }
              ]
            },
            {
              t: 'figure',
              a: { class: 'blue inset' },
              c: { t: 'div', c: { t: 'p' }}
            }
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane top-half-offset' },
              c: [
                { t: 'h2', c: 'Interactivity and animations' },
                { t: 'p', c: 'Bring your designs to life with animations that add movement and interactivity. Create transitions, hover effects, and micro-animations to enhance user experiences.' },
                { t: 'p', c: 'Control timing, easing, and keyframes to ensure smooth, polished motion that aligns with your design vision.' },
                {
                  t: 'ul',
                  a: { class: 'section-list orange' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Transitions and state animations' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Easing and timing controls' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Micro-interactions' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Interactive prototyping' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'orange', href: '/guides#realtime-vector-editing' },
                  c: [
                    'Learn more about creating animations',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'side right inset pale-orange half-offset' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'three-two', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.8' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark top-bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Design versioning' },
                { t: 'p', c: 'Track changes, save iterations, and revisit previous versions of your work with ease.' },
                { t: 'p', c: 'Collaborate confidently, knowing you can experiment freely without losing progress or overwriting important designs.' },
                {
                  t: 'a',
                  a: { class: 'pink', href: '/features' },
                  c: [
                    'Learn more about versioning',
                    { t: 'i', a: { class: 'fa fa-chevron-right' } }
                  ]
                }
              ]
            },
            { t: 'figure', a: { class: 'pink inset' }, c: { t: 'div', c: { t: 'p' }}}
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light top-half-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Create organizations' },
                { t: 'p', c: 'Organizations help you centralize teams, projects, and assets, ensuring everything stays structured and accessible.' },
                { t: 'p', c: 'With role-based permissions, shared resources, and version control, you can maintain consistency, improve collaboration, and scale your workflows efficiently.' },
                {
                  t: 'ul',
                  a: { class: 'section-list pink' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Project management tools' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Asset sharing' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'pink', href: '/guides#code-generation' },
                  c: [
                    'Learn how to create organizations',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Build teams' },
                { t: 'p', c: 'Collaborate efficiently by bringing designers, developers, and stakeholders together in one workspace.' },
                { t: 'p', c: 'Assign roles, share feedback, and stay organized to keep projects moving forward.' },
                {
                  t: 'ul',
                  a: { class: 'section-list pink' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Role-based permissions' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Commenting and feedback' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Shared team libraries' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'pink', href: '/guides#code-generation' },
                  c: [
                    'Learn more about role-based permissions',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'center pink-half half' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'four-three' },
            c: { 
              t: 'o-editor', 
              a: { scale: '0.6', animation: 'test', config: 'observatoryEditor' } 
            }
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark top-bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Code generation and handoff' },
                { t: 'p', c: 'Community-driven code generation bridges the gap between design and development by providing shared, reusable code from designs in the language and framework your project uses.' },
                {
                  t: 'a',
                  a: { class: 'orange', href: '/guides#code-generation' },
                  c: [
                    'Learn more about code generation',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'figure',
              a: { class: 'pale-orange inset' },
              c: {
                t: 'o-image',
                a: {
                  path: 'https://s3.amazonaws.com/octo.coffee/marketing/47c0f4b7-f87f-4a0b-a691-ee0ac5926ebc.png%3Fcolor%3D%23232323',
                  alt: 'Private organizations'
                }
              }
            }
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane top-half-offset' },
              c: [
                { t: 'h2', c: 'Dynamic style guides' },
                { t: 'p', c: 'Automatically update your style guide as your design evolves, ensuring your team always works with the latest components, colors, and typography.' },
                { t: 'p', c: 'Keep your design system consistent, flexible, and easy to maintain across projects.' },
                {
                  t: 'ul',
                  a: { class: 'section-list pale-green' },
                  c: [
                    { t: 'li', c: { t: 'o-check', i: { content: 'Live design system' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Color and typography' }}},
                    { t: 'li', c: { t: 'o-check', i: { content: 'Customizable tokens' }}}
                  ]
                },
                {
                  t: 'a',
                  a: { class: 'pale-green', href: '/guides#realtime-vector-editing' },
                  c: [
                    'Learn more about dynamic style guides',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'side right inset pale-green half-offset' },
          c: {
            t: 'o-figure',
            i: { frame: 'browser', aspect: 'three-two', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.8' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark top-bottom-offset' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half reverse' },
          c: [
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Component and element annotation' },
                { t: 'p', c: 'Let teams mark up designs with precise feedback tied to specific elements.' },
                { t: 'p', c: 'This ensures everyone stays aligned, makes reviews more efficient, and keeps all discussions organized in one place.' },
                {
                  t: 'a',
                  a: { class: 'pink', href: '/guides#code-generation' },
                  c: [
                    'Learn more about comments',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'figure',
              a: { class: 'pink inset' },
              c: {
                t: 'o-image',
                a: {
                  path: 'https://s3.amazonaws.com/octo.coffee/marketing/47c0f4b7-f87f-4a0b-a691-ee0ac5926ebc.png%3Fcolor%3D%23232323',
                  alt: 'Private organizations'
                }
              }
            }
          ]
        }
      ]
    }
  ]
};

export default features;
