import { CoreHTMLElement, serviceManager, coreObject, text } from '../../app.external/core/index.mjs';

/**
 * Async button with loading state intended for client services.
 */
export default class ODCAsyncButtonHTMLElement extends CoreHTMLElement {
  constructor() {
    super();

    this.serviceManager = serviceManager;
    this.coreObject = coreObject;
    this.text = text;

    /**
     * Name of the validate function found in the service that enables the button.
     * @type {string}
     */
    this.validate_attribute = this.getAttribute('validate');

    if (this.text.hasText(this.validate_attribute) && !this.validate) {
      this.validate = this.validate_attribute;
    }

    /**
     * Stringified properties for call function.
     * @type {string}
     */
    this.props_attribute = this.getAttribute('props');

    if (this.text.hasText(this.props_attribute) && !this.props) {
      this.props = this.props_attribute;
    }

    /**
     * Determines if the button should detect global on Enter event (for form use).
     * @type {boolean}
     */
    this.keyboard_attribute = this.hasAttribute('keyboard');

    if (this.keyboard_attribute && !this.keyboard) {
      this.keyboard = this.keyboard_attribute;
    }

    this.is_disabled = false;

    /**
     * Bind scope.
     */
    this.callFn = this.callFn.bind(this);
    this.enterFn = this.enterFn.bind(this);
    this.setValid = this.setValid.bind(this);
  }

  /**
   * Sets the loading state on he button.
   * @param {boolean} is_loading
   */
  setLoading(is_loading) {
    if (is_loading) {
      this.classList.add('loading');
      this.setAttribute('disabled', '');
      this.is_disabled = true;

      this.renderElementByJSON({ 
        t: 'i', a: { class: 'fa fa-spinner-third fa-spin'}
      }, this);
    } else {
      this.classList.remove('loading');
      this.removeAttribute('disabled');
      this.is_disabled = false;

      this.renderElementByJSON({ 
        t: 'span', 
        a: this.icon ? { class: this.icon } : undefined,
        c: this.label 
      }, this);
    }
  }

  /**
   * Checks to see if the button should be enabled against the service and sets state accordingly.
   */
  setValid() {
    const service = this.serviceManager.get(this.service);

    if (service && typeof service[this.validate] === 'function') {
      const is_valid = service[this.validate]();

      if (is_valid) {
        this.removeAttribute('disabled');
        this.is_disabled = false;
      } else {
        this.setAttribute('disabled', '');
        this.is_disabled = true;
      }
    }
  }

  /**
   * For entering button with keyboard - on window.
   * @param {KeyboardEvent} event
   */
  async enterFn(event) {
    event.stopImmediatePropagation();

    if (event.key === 'Enter') {
      this.callFn();
    }
  }

  async callFn() {
    if (!this.is_disabled) {
      this.setLoading(true);

      const service = this.serviceManager.get(this.service);
      const props = this.props?.replace(/'/g, '"');
      const args = props ? JSON.parse(props) : [];

      if (service) {
        if (this.coreObject.isAsyncFunction(service[this.getValueSet()])) {
          await service[this.getValueSet()]?.(...args);
        } else {
          service[this.getValueSet()]?.(...args);
        }
      }

      this.setLoading(false);
    }
  }

  connectedCallback() {
    this.renderElementByJSON({ 
      t: 'span', 
      a: this.icon ? { class: this.icon } : undefined,
      c: this.label 
    }, this);

    this.classList.add(this.class || 'save');

    this.addEventListener('click', this.callFn);

    if (this.keyboard) {
      window.addEventListener('keydown', this.enterFn);
    }

    if (this.validate) {
      const service = this.serviceManager.get(this.service);

      if (service?.[this.getValueWatch()]) {
        service?.[this.getValueWatch()](this.setValid);
      }

      this.setValid();
    }

    setTimeout(() => {
      this.classList.add('fx-on');
    }, 500);
  }

  postDisconnect() {
    this.removeEventListener('click', this.callFn);

    window.removeEventListener('keydown', this.enterFn);
  }
}

window.customElements.define('o-async-button', ODCAsyncButtonHTMLElement);