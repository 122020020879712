const windows = {
  name: 'windows',
  title: 'Download Windows',
  route: '/windows',
  type: 'main',
  view_class: null,
  view: [
    {
      t: 'o-hero',
      a: { class: 'right'},
      i: { scroll_amount: 60, color: 'blue' },
      c: [
        { t: 'o-greeblie-group', i: { position: 'above', color_1: '#0088DD', color_2: '#9900FF' }},
        { t: 'o-greeblie-group', i: { position: 'below-device', color_1: '#0088DD', color_2: '#9900FF' }},
        {
          t: 'div',
          a: { class: 'content' },
          c: [
            {
              t: 'div',
              a: { class: 'hero-block' },
              c: [
                { t: 'h1', a: { class: 'medium' }, c: [ 'Octo for Windows' ] },
                { t: 'o-app-version', a: { platform: 'electron', os: 'windows' } },
                { 
                  t: 'o-app-download', 
                  a: { platform: 'electron', os: 'windows', icon: 'fab fa-windows' } 
                }
              ]
            },
            {
              t: 'div',
              a: { class: 'hero-figure floating' },
              c: { t: 'o-figure', i: { frame: 'windows' }, c: { t: 'o-editor', a: { scale: '0.6' }}}
            }
          ]
        }
      ]
    },
    { t: 'div', a: { class: 'block light tall' }, c: { t: 'o-mask' }}
  ]
};

export default windows;