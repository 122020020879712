const guides = {
  name: 'guides',
  title: 'Guides',
  route: '/guides',
  type: 'main',
  view_class: null,
  view: [
    {
      t: 'o-hero',
      a: { class: 'short right' },
      i: { scroll_amount: 60, color: 'pale-orange' },
      c: [
        { t: 'o-greeblie-group', i: { position: 'above', color_1: '#9900ff', color_2: '#ff5794' }},
        { t: 'o-greeblie-group', i: { position: 'below', color_1: '#9900ff', color_2: '#ff5794' }},
        {
          t: 'div',
          a: { class: 'content' },
          c: [
            {
              t: 'div',
              a: { class: 'hero-block' },
              c: [
                { t: 'h1', c: 'Guides' },
                { t: 'p', c: 'This is the way' }
              ]
            }
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark top-more-offset bottom-offset skew above' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Get started designing' },
                { t: 'p', c: 'Guides that will help you get familiar with Octo quickly so you can start collaborating with your team to build better products.' },
                {
                  t: 'a',
                  a: { href: '/guides#basics' },
                  c: [
                    'Start with the basics',
                    { t: 'i', a: { class: 'fa fa-chevron-right' }}
                  ]
                }
              ]
            },
            {
              t: 'section',
              a: { class: 'plane' },
              c: [
                { t: 'h2', c: 'Explore other topics' },
                { t: 'p', c: 'Explore tutorials, tips, and best practices to get the most out of Octo.' },
                {
                  t: 'ul',
                  a: { class: 'section-list blue' },
                  c: [
                    { 
                      t: 'li', 
                      c: {
                        t: 'a',
                        a: { 
                          href: 'https://discord.com/channels/1197421122610806884',
                          rel: 'noopener',
                          target: '_blank'
                        },
                        c: [
                          'Join the conversation on Discord',
                          { t: 'i', a: { class: 'far fa-external-link-square' }}
                        ]
                      }
                    },
                    { 
                      t: 'li', 
                      c: {
                        t: 'a',
                        a: { 
                          href: 'https://github.com/octo-design',
                          rel: 'noopener',
                          target: '_blank'
                        },
                        c: [
                          'Code generation resources on GitHub',
                          { t: 'i', a: { class: 'far fa-external-link-square' }}
                        ]
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane top-half-offset' },
              c: [
                { t: 'h2', a: { id: 'components' }, c: 'Adding elements and components' },
                {
                  t: 'p',
                  c: [
                    'Create components with ease by opening the ',
                    { t: 'a', c: 'elements menu ' },
                    ', and then selecting an element or component from the list.'
                  ]
                },
                {
                  t: 'p',
                  c: [
                    'This will incorporate the newly selected item into your design, allowing you to manipulate it\'s properties from the ',
                    { t: 'a', c: 'properties side menu' },
                    '.'
                  ]
                },
                {
                  t: 'p',
                  c: [
                    'Inversely, elements and components can be removed from your design by selecting them and pressing the ',
                    { t: 'b', c: 'delete ' },
                    'key or right-clicking on them in the ',
                    { t: 'a', c: 'layers menu ' },
                    'and clicking the ',
                    { t: 'b', c: 'delete ' },
                    'option.'
                  ]
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'side right inset pale-orange top-bottom-offset' },
          c: [
            {
              t: 'o-figure',
              i: { aspect: 'three-two', frame: 'browser', limit: '' },
              c: {
                t: 'o-editor',
                a: { scale: '0.9', config: 'createComponents', animation: 'createComponents' }
              }
            }
          ]
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', a: { id: 'realtime-vector-editing' }, c: 'Realtime vector editing' },
              {
                t: 'p',
                c: [
                  'All components are made up of basic elements that can be altered from the ',
                  { t: 'a', c: 'properties side menu ' },
                  'or by moving nodes around the ',
                  { t: 'a', c: 'canvas' },
                  '.'
                ]
              },
              {
                t: 'p',
                c: 'All alterations to the design are synced with all active editors in realtime.'
              }
            ]
          }
          
        },
        {
          t: 'div',
          a: { class: 'side right inset pink half-offset' },
          c: {
            t: 'o-figure',
            i: { aspect: 'three-two', frame: 'browser', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.8', config: 'test', animation: 'test' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', a: { id: 'creating-element-interactions' }, c: 'Creating element interactions' },
              {
                t: 'p',
                c: [
                  'Add interactions, such as events, click behavior, navigation and others to any element by selecting the element and adding behavior from the ',
                  { t: 'a', c: 'events menu' },
                  '.'
                ]
              },
              { t: 'p' },
              { t: 'p' }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right inset pink half-offset' },
          c: {
            t: 'o-figure',
            i: { aspect: 'three-two', frame: 'browser', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.9' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', a: { id: 'annotating-components' }, c: 'Annotating components' },
              {
                t: 'p',
                c: [
                  'You can easily comment and annotate elements from the ',
                  { t: 'a', c: 'comments menu ' },
                  'my writing a new comment and then pressing on the element selection button and then selecting element.'
                ]
              }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right inset pink half-offset' },
          c: {
            t: 'o-figure',
            i: { aspect: 'three-two', frame: 'browser', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.9' } }
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', a: { id: 'code-generation' }, c: 'Code generation' },
              { t: 'p' }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right inset pink half-offset' },
          c: {
            t: 'o-figure',
            i: { aspect: 'three-two', frame: 'browser', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.9' } }
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'dark very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', a: { id: 'managing-users-in-design' }, c: 'Managing users in design' },
              { t: 'p' }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right inset pink half-offset' },
          c: {
            t: 'o-figure',
            i: { aspect: 'three-two', frame: 'browser', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.9' } }
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: [
            {
              t: 'section',
              a: { class: 'plane top-half-offset' },
              c: [
                { t: 'h2', a: { id: 'design-versioning' }, c: 'Design versioning' },
                {
                  t: 'p',
                  c: [
                    'By default all new designs are unversioned allowing users to start with any version they like. New versions can always be created by pressing on the ',
                    { t: 'a', c: 'version placeholder control ' },
                    'to open the ',
                    { t: 'a', c: 'version menu ' },
                    'and defining a new version tag.'
                  ]
                },
                {
                  t: 'p',
                  c: [
                    'Additionally, set any version as the default by simply clicking the ',
                    { t: 'b', c: 'default ' },
                    'button located in the ',
                    { t: 'a', c: 'version menu' },
                    '.'
                  ]
                }
              ]
            }
          ]
        },
        {
          t: 'div',
          a: { class: 'side right inset purple half-offset' },
          c: {
            t: 'o-figure',
            i: { aspect: 'three-two', frame: 'browser', limit: '' },
            c: { t: 'o-editor', a: { scale: '0.9' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', a: { id: 'creating-an-organization' }, c: 'Creating an organization' },
              {
                t: 'p',
                c: [
                  'By default all new designs are unversioned allowing users to start with any version they like. New versions can always be created by pressing on the ',
                  { t: 'a', c: 'version placeholder control ' },
                  'to open the ',
                  { t: 'a', c: 'version menu ' },
                  'and defining a new version tag.'
                ]
              },
              {
                t: 'p',
                c: [
                  'Additionally, set any version as the default by simply clicking the ',
                  { t: 'b', c: 'default ' },
                  'button located in the ',
                  { t: 'a', c: 'version menu' },
                  '.'
                ]
              }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right inset pale-orange half-offset' },
          c: {
            t: 'o-figure',
            i: { aspect: 'three-two', frame: 'browser', limit: '' },
            c: { t: 'o-projects', a: { scale: '0.9', config: 'createOrganization' }}
          }
        }
      ]
    },
    {
      t: 'o-block',
      a: { class: 'light very-tall' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content half' },
          c: {
            t: 'section',
            a: { class: 'plane top-half-offset' },
            c: [
              { t: 'h2', a: { id: 'changing-light-modes' }, c: 'Changing light modes' },
              { 
                t: 'p', 
                c: 'Octo offers 3 light modes for day/night editing as well as a neutral gray mode.' 
              },
              {
                t: 'p',
                c: [
                  'Light modes can be changed easily from the ',
                  { t: 'a', c: 'editors settings menu' },
                  '.'
                ]
              }
            ]
          }
        },
        {
          t: 'div',
          a: { class: 'side right inset pink half-offset' },
          c: { 
            t: 'o-figure', 
            i: { aspect: 'three-two', frame: 'browser', limit: '' }, 
            c: { t: 'o-projects', a: { scale: '0.9' }} 
          }
        }
      ]
    }
  ]
};

export default guides;