import { CoreHTMLElement, numbers } from '../../app.external/core/index.mjs';
import viewScroll from '../../app.services/view/scroll.mjs';

/**
 * For scroll range detection.
 */
export default class Scroll extends CoreHTMLElement {
  constructor() {
    super();

    this.numbers = numbers;
    this.viewScroll = viewScroll;

    /**
     * @private
     * @type {number}
     */
    this.top_offset = 0;

    /**
     * Scroll above the element.
     * @type {boolean}
     */
    this.is_scroll_above = false;

    /**
     * Scroll below the element.
     * @type {boolean}
     */
    this.is_scroll_below = false;

    /**
     * Scroll over the element.
     * @type {boolean}
     */
    this.is_scroll_over = false;

    /** Global positions */

    /**
     * Scroll at the top of the page.
     * @type {boolean}
     */
    this.is_scroll_top = false;

    /**
     * Scroll in the middle of the page.
     * @type {boolean}
     */
    this.is_scroll_middle = false;

    /**
     * Color when over the element.
     * @type {string}
     */
    if (!this.color) {
      this.color = undefined;
    }

    /**
     * Bind scope.
     */
    this.move = this.move.bind(this);
  }

  /**
   * Observes scroll position.
   * @param {number} top_offset
   */
  watchScroll(top_offset) {
    this.top_offset = this.numbers.toNumber(top_offset);

    window.addEventListener('scroll', this.move);

    this.is_scroll_over = undefined;

    this.move();
  }

  unwatchScroll() {
    window.removeEventListener('scroll', this.move);

    this.is_scroll_top = window.scrollY < 60;
    this.is_scroll_middle = window.scrollY >= 60;

    this.viewScroll.paintOff({
      is_scroll_over: false,
      is_scroll_above: false,
      is_scroll_below: false,
      is_scroll_top: this.is_scroll_top,
      is_scroll_middle: this.is_scroll_middle
    });
  }

  /**
   * When scrollbar moves.
   */
  move() {
    // eslint-disable-next-line no-undef
    requestAnimationFrame(() => {
      const top = this.offsetTop + this.top_offset;
      const bottom = this.offsetHeight + this.offsetTop;
      const is_scroll_over = window.scrollY >= top && window.scrollY <= bottom;
  
      this.is_scroll_above = window.scrollY < top;
      this.is_scroll_below = window.scrollY > bottom;
  
      this.is_scroll_top = window.scrollY < 60;
      this.is_scroll_middle = window.scrollY >= 60;
  
      /**
       * Limit the amount of function calls.
       */
      if (is_scroll_over && is_scroll_over !== this.is_scroll_over) {
        this.is_scroll_over = is_scroll_over;
  
        this.painOn();
        this.viewScroll.paintOn({
          color: this.color,
          is_scroll_over: this.is_scroll_over,
          is_scroll_above: this.is_scroll_above,
          is_scroll_below: this.is_scroll_below,
          is_scroll_top: this.is_scroll_top,
          is_scroll_middle: this.is_scroll_middle
        });
      } else if (!is_scroll_over && is_scroll_over !== this.is_scroll_over) {
        this.is_scroll_over = is_scroll_over;
  
        this.paintOff();
        this.viewScroll.paintOff({
          is_scroll_over: this.is_scroll_over,
          is_scroll_above: this.is_scroll_above,
          is_scroll_below: this.is_scroll_below,
          is_scroll_top: this.is_scroll_top,
          is_scroll_middle: this.is_scroll_middle
        });
      }
    });
  }

  /**
   * For applying a change when scroll of over the element.
   */
  painOn() {}

  /**
   * For removing the applied change when scroll is not over the element.
   */
  paintOff() {}
}