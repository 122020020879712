import { CoreHTMLElement } from '../../app.external/core/index.mjs';

/**
 * Line mask visual element.
 */
export default class HTMLOMask extends CoreHTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.renderElementByJSON({
      t: 'div', 
      a: { class: 'lines' },
      c: [
        { t: 'div' },
        { t: 'div' },
        { t: 'div' },
        { t: 'div' }
      ]
    }, this);
  }
}

window.customElements.define('o-mask', HTMLOMask);