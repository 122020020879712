import { Router } from '../app.external/core/index.mjs';

import missing from './404/404.mjs';

import privacy from './legal/privacy.mjs';
import terms from './legal/terms.mjs';

import home from './main/home.mjs';
import blog from './main/blog.mjs';
import company from './main/company.mjs';
import features from './main/features.mjs';
import pricing from './main/pricing.mjs';

import android from './platforms/android.mjs';
import ios from './platforms/ios.mjs';
import macos from './platforms/macos.mjs';
import windows from './platforms/windows.mjs';

import contact from './resources/contact.mjs';
import guides from './resources/guides.mjs';

import './components.mjs';
import './services.mjs';

class OView extends Router {
  constructor() {
    super();

    // this.redirect_routes = {
    //   '/': '/projects/recent'
    // };

    this.routes.push(missing);

    this.routes.push(privacy);
    this.routes.push(terms);

    this.routes.push(home);
    this.routes.push(blog);
    this.routes.push(company);
    this.routes.push(features);
    this.routes.push(pricing);

    this.routes.push(android);
    this.routes.push(ios);
    this.routes.push(macos);
    this.routes.push(windows);

    this.routes.push(contact);
    this.routes.push(guides);
  }
}

window.customElements.define('o-view', OView);