import { CoreHTMLElement, coreDate } from '../../app.external/core/index.mjs';
import manageBilling from '../../app.services/managers/billing.mjs';

export default class HTMLOPlans extends CoreHTMLElement {
  constructor() {
    super();

    this.coreDate = coreDate;
    this.manageBilling = manageBilling;

    /** @type {HTMLElement} */
    this.root = undefined;

    /**
     * Bind scope.
     */
    this.render = this.render.bind(this);
  }

  /**
   * Returns the plan layout.
   * @param {*} plans 
   * @returns {string}
   */
  getLayout(plans) {
    if (plans?.length === 1) {
      return 'one';
    }

    if (plans?.length === 2) {
      return 'two';
    }

    return 'three';
  }

  /**
   * Returns the path to a plan.
   * @param {string} plan 
   * @param {string} interval 
   * @returns {string}
   */
  getPath(plan, interval) {
    const path = 'main.account.billing-checkout';
    const link = `${window.api.editor_domain}?path=${path}&plan=${plan}&interval=${interval}`;

    return encodeURI(link);
  }

  /**
   * Renders prices.
   */
  render(prices) {
    if (prices?.plansAndFeatures?.plans?.length) {
      this.clearElement(this.root);

      this.root.classList.add(this.getLayout(prices?.plansAndFeatures?.plans));

      for (const plan of prices.plansAndFeatures.plans) {
        if (plan.is_active) {
          const node = this.renderElementByJSON({
            t: 'div',
            a: { class: 'price', style: `background-color: ${plan.primary_color};` },
            c: [
              { t: 'h2', a: { style: `color: ${plan.accent_color};` }, c: plan.name }, 
              { 
                t: 'h3', 
                a: { style: `color: ${plan.accent_color};` },
                c: plan.price ? [
                  { t: 'span', c: plan.price.unit_amount / 100 },
                  { t: 'span', a: { class: 'unit' }, c: `/ ${plan.price.recurring.interval}` }
                ] : 'Free'
              },
              { t: 'p', a: { style: `color: ${plan.font_color};` }, c: plan.description },
              { 
                t: 'a', 
                a: { 
                  style: `background: ${plan.accent_color}; color: ${plan.primary_color};`,
                  href: this.getPath(plan._id, plan?.price?.recurring?.interval),
                  rel: 'noopener'
                }, 
                c: 'Select' 
              }
            ]
          });
  
          this.root.append(node);
        }
      }
    }
  }

  connectedCallback() {
    this.renderElementByJSON({
      t: 'div',
      a: { class: 'plans' },
      r: 'root'
    }, this); 

    this.manageBilling.watch(this.render);
    this.manageBilling.load();
  }

  disconnectedCallback() {
    this.manageBilling.unwatch(this.render);
  }  
}

window.customElements.define('o-pricing-plans', HTMLOPlans);