import { CoreHTMLElement, text } from '../../app.external/core/index.mjs';

export default class HTMLOAccordion extends CoreHTMLElement {
  constructor() {
    super();

    this.text = text;

    /** @type {HTMLElement} */
    this.aRoot = undefined;
    /** @type {HTMLElement} */
    this.iconRoot = undefined;
    /** @type {HTMLElement} */
    this.sectionRoot = undefined;

    this.heading_attribute = this.getAttribute('heading');

    if (this.text.hasText(this.heading_attribute) && !this.heading) {
      this.heading = this.heading_attribute;
    }

    this.is_on = false;

    /**
     * Bind scope.
     */
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    if (this.sectionRoot) {
      this.is_on = !this.is_on;

      if (this.is_on) {
        this.sectionRoot.classList.add('on');
        this.iconRoot.classList.add('fa-minus');
        this.iconRoot.classList.remove('fa-plus');
      } else {
        this.sectionRoot.classList.remove('on');
        this.iconRoot.classList.remove('fa-minus');
        this.iconRoot.classList.add('fa-plus');
      }
    }
  }

  connectedCallback() {
    this.renderElementsByJSON([
      { 
        t: 'a', 
        r: 'aRoot',
        e: { click: this.toggle },
        a: { role: 'button' },
        c: [
          { t: 'i', r: 'iconRoot', a: { class: 'far fa-plus' }},
          { t: 'span', c: this.heading }
        ]
      },
      {
        t: 'div',
        r: 'sectionRoot',
        h: this.innerHTML
      }
    ], this);
  }
}

window.customElements.define('o-accordion', HTMLOAccordion);