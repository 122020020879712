import { ControlHTMLElement, text } from '../../app.external/core/index.mjs';
import toolList from "../../app.services/presets/tool-list.mjs";
import keyboard from "../../app.services/keyboard/keyboard.mjs";
import view from "../../app.services/view/view.mjs";

export default class ODCListHTMLElement extends ControlHTMLElement {
  constructor() {
    super();

    this.keyboard = keyboard;
    this.view = view;
    this.toolList = toolList;
    this.text = text;

    /** @type {HTMLElement} */
    this.listRoot = undefined;
    /** @type {HTMLElement} */
    this.labelRoot = undefined;

    /**
     * Determines if the options list is shown or hidden.
     * @type {boolean}
     * */
    this.is_on = false;

    /**
     * The index of a selected item.
     * @type {number}
     */
    this.index = undefined;

    /**
     * Name of the list to populate the control.
     * @type {string}
     */
    this.list_attribute = this.getAttribute('list');

    if (this.text.hasText(this.list_attribute)) {
      this.list = this.list_attribute;
    }

    /**
     * Populated list content.
     */
    this.list_items = undefined;

    // /**
    //  * Name of the label property found in the list.
    //  * @type {string}
    //  */
    this.option_label = this.getAttribute('option-label') || 'name';

    // /**
    //  * Name of the value property found in the list.
    //  * @type {string}
    //  */
    this.option_id = this.getAttribute('option-id') || 'value';

    this.has_up_arrow = true;
    this.has_down_arrow = true;
  }

  renderValue() {
    if (this.listRoot) {
      this.index = this.list_items?.findIndex(item => item[this.option_id] === this.value);

      if (this.index !== -1) {
        const selected = this.listRoot.childNodes[this.index];

        this.cleanList();

        // @ts-ignore
        selected?.classList?.add('on');
      }

      this.renderLabel();
    }
  }

  /**
   * Renders the internal list with options based on the name of the list.
   */
  renderList() {
    this.clearElement(this.listRoot);

    for (const [index, list_item] of this.list_items.entries()) {
      const label = list_item[this.option_label];
      const id = list_item[this.option_id];

      const node = this.renderElementByJSON({
        t: 'li', 
        a: { role: 'button', tabindex: '0' }, 
        e: [
          { click: this.selectProperty.bind(this, id, index) },
          { keyup: this.enterProperty.bind(this, id, index) }
        ],
        c: label
      });

      if (!label) {
        node.classList.add('hr');
      }
  
      if (this.index === index) {
        node.classList.add('on');
      }
      
      this.listRoot.append(node);
    }
  }

  /**
   * Renders the label for a list item.
   */
  renderLabel() {
    if (this.labelRoot) {
      const label = this.list
        ? this.list_items?.find(item => item[this.option_id] === this.value) : '';

      if (label) {
        this.renderElementsByJSON([
          label[this.option_label],
          { t: 'i', a: { class: 'fal fa-chevron-down' }}
        ], this.labelRoot);
      } else {
        this.renderElementByJSON({
          t: 'i', a: { class: 'fal fa-chevron-down' }
        }, this.labelRoot);
      }
    }
  }

  /**
   * Triggers a set value of the focused list option.
   * @param {string} id
   * @param {number} index
   * @param {KeyboardEvent} event
   */
  enterProperty(id, index, event) {
    if (event.key === 'Enter') {
      this.selectProperty(id, index);
    }
  }

  /**
   * Sets the value of the selected list option.
   * @param {string} id
   * @param {number} index
   * @param {Event} [event]
   */
  selectProperty(id, index, event) {
    event?.stopPropagation();

    this.index = index;

    this.setValue(id);
    this.renderValue();
    this.closeList();
  }

  /**
   * Toggles the lists visibility.
   */
  toggleList() {
    if (this.listRoot) {
      this.is_on = !this.is_on;

      if (this.is_on) {
        this.listRoot.classList.add('on');

        this.keyboard.suspend();
        this.keyboard.add('Escape', null, () => {
          this.closeList();
        });

        this.view.addClickToClose(this, () => {
          this.closeList();
        });

        this.bindArrows();
      } else {
        this.listRoot.classList.remove('on');

        this.keyboard.resume();
        this.keyboard.remove('Escape', null);
        this.view.removeClickToClose();
        this.unbindArrows();
      }
    }
  }

  /**
   * Cleans the options list from states.
   */
  cleanList() {
    for (const item of this.listRoot.childNodes) {
      // @ts-ignore
      item.classList.remove('on');
    }
  }

  connectedCallback() {
    this.renderElementsByJSON([
      { t: 'span', r: 'labelRoot' },
      { t: 'ul', a: { class: 'select small' }, r: 'listRoot' }
    ], this);
   
    this.addEventListener('click', this.toggleList.bind(this));

    this.list_items = this.toolList[this.list];

    this.renderLabel();

    if (this.list_items?.length) {
      this.renderList();
    } else {
      throw { error: `Unable to find ${this.list} list.`, element: this };
    }

    this.addListRoot(this.listRoot);
    this.postConnected();
  }

  closeList() {
    this.is_on = false;

    if (this.listRoot) {
      this.listRoot.classList.remove('on');
    }

    this.keyboard.resume();
    this.keyboard.remove('Escape', null);
    this.view.removeClickToClose();
    this.unbindArrows();
  }
}

window.customElements.define('o-list', ODCListHTMLElement);