const blog = {
  name: 'blog',
  title: 'Blog',
  route: '/blog',
  type: 'main',
  view_class: null,
  view: [
    { 
      t: 'o-hero',
      a: { class: 'dark-text short right' },
      i: { scroll_amount: 60, color: 'orange' },
      c: [
        { t: 'o-greeblie-group', i: { position: 'above', color_1: '#00D283', color_2: '#00B8D2' }},
        { t: 'o-greeblie-group', i: { position: 'below', color_1: '#00D283', color_2: '#00B8D2' }},
        { 
          t: 'div', 
          a: { class: 'content' },
          c: {
            t: 'div',
            a: { class: 'hero-block' },
            c: [
              { t: 'h1', c: 'Blog' },
              { t: 'p', c: 'What we\'ve been up to' }
            ]
          }
        }
      ] 
    },
    { t: 'o-posts' },
    {
      t: 'o-block',
      a: { class: 'dark top-bottom-offset short' },
      c: [
        { t: 'o-mask' },
        {
          t: 'div',
          a: { class: 'content tight' },
          c: [
            {
              t: 'section',
              a: { class: 'plane center' },
              c: { 
                t: 'p', 
                c: [
                  'For the latest community updates, follow us on ',
                  { 
                    t: 'a', 
                    a: { 
                      class: 'static',
                      href: 'https://discord.com/channels/1197421122610806884',
                      rel: 'noopener',
                      target: '_blank'
                    }, 
                    c: [
                      'Discord ',
                      { t: 'i', a: { class: 'far fa-external-link-square' }}
                    ]
                  }
                ]
              }
            },
          ]
        }
      ]
    },
  ]
};

export default blog;