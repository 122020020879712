import { CoreHTMLElement, router } from "../../app.external/core/index.mjs";
import confirmation from "../../app.services/confirmation/confirmation.mjs";

/**
 * Global confirmation dialog.
 */
export default class OConfirmationHTMLElement extends CoreHTMLElement {
  constructor() {
    super();

    this.confirmation = confirmation;
    this.router = router;

    /** @type {HTMLElement} */
    this.yesRoot = undefined;
    /** @type {HTMLElement} */
    this.noRoot = undefined;
    /** @type {HTMLElement} */
    this.iconRoot = undefined;
    /** @type {HTMLElement} */
    this.messageRoot = undefined;

    /** @type {boolean} */
    this.has_controls = false;
    /** @type {*} */
    this.accept_fn = undefined;

    /**
     * Time needed per letter in milliseconds.
     *
     * Note: Assumes reading level of 238 words/minute or ~4 letters/sec.
     * @number
     */
    this.LETTERS_PER_SEC = 0.00396;

    /**
     * Bind scope.
     */
    this.yes = this.yes.bind(this);
    this.no = this.no.bind(this);
    this.open = this.open.bind(this);
    this.center = this.center.bind(this);
    this.close = this.close.bind(this);
  }

  /**
   * Configures and opens the confirmation dialog.
   */
  open({ result, is_decision, accept, dismiss, sticky }) {
    this.center();
    this.accept_fn = accept;

    if (dismiss) {
      this.close();
    } else {
      this.has_controls = is_decision;

      if (!result?.is_error) {
        this.message = result?.message || 'Operation succeeded.';
        this.icon = 'success fal fa-check';
      } else {
        this.message = result.message || 'Operation failed.';
        this.icon = 'fail fal fa-times';
      }

      if (result?.icon) {
        this.icon = `success fal ${result.icon}`;
      }

      if (this.has_controls) {
        this.classList.add('controls');
      } else {
        this.classList.remove('controls');
      }

      this.renderElementByJSON(this.message, this.messageRoot);
      this.iconRoot.className = this.icon;
      this.classList.add('on');

      const letters = this.message.length / this.LETTERS_PER_SEC;
      const timeout = is_decision ? letters : (letters / 2);

      clearTimeout(this.timer);

      if (!sticky) {
        this.timer = setTimeout(() => {
          this.close();
        }, timeout);
      }
    }
  }

  /**
   * Closes the confirmation dialog.
   */
  close() {
    clearTimeout(this.timer);
    this.classList.remove('on');
  }

  /**
   * Triggers accept function passed.
   */
  yes() {
    this.accept_fn?.();
    this.close();
  }

  /**
   * Closes confirmation.
   */
  no() {
    this.close();
  }

  /**
   * Centers the confirmation on screen.
   */
  center() {
    const route = this.router.getCurrentRoute();

    /**
     * Already center when not in editor.
     */
    if (!route?.path?.startsWith('/editor')) {
      this.style.marginLeft = '0';

      return;
    }
  }

  connectedCallback() {
    this.renderElementByJSON({
      t: 'div',
      c: [
        { t: 'i', r: 'iconRoot' },
        { t: 'p', r: 'messageRoot' },
        {
          t: 'a',
          a: { class: 'far fa-check', role: 'button', 'aria-label': 'Yes' },
          e: { click: this.yes },
          r: 'yesRoot'
        },
        {
          t: 'a',
          a: { class: 'far fa-times show', role: 'button', 'aria-label': 'No' },
          e: { click: this.no },
          r: 'noRoot'
        }
      ]
    }, this);

    this.confirmation.watch(this.open);

    window.addEventListener('resize', this.center);

    this.router.watch(this.close);
  }

  disconnectedCallback() {
    this.yesRoot.removeEventListener('click', this.yes);
    this.noRoot.removeEventListener('click', this.no);

    this.confirmation.unwatch(this.open);
    this.router.unwatch(this.close); 

    window.removeEventListener('resize', this.center);
  }
}

window.customElements.define('o-confirmation', OConfirmationHTMLElement);