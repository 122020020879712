const missing = {
  name: '404',
  title: '404',
  route: '/404',
  type: 'main',
  view_class: null,
  view: {
    t: 'o-hero',
    a: { class: 'center tall' },
    i: { scroll_amount: 60, color: 'blue' },
    c: [
      { t: 'o-greeblie-group', i: { position: 'above', color_1: '#040D21', color_2: '#0C2D6B' }},
      { t: 'o-greeblie-group', i: { position: 'below-home', color_1: '#040D21', color_2: '#0C2D6B' }},
      {
        t: 'div',
        a: { class: 'content' },
        c: {
          t: 'div',
          a: { class: 'hero-block center' },
          c: [
            { t: 'h1', c: 'Oh no!' },
            { t: 'p', c: 'This page does not exist.' },
            {
              t: 'a',
              a: { href: '/home' },
              c: [
                'Go back home',
                { t: 'i', a: { class: 'fa fa-chevron-right' }}
              ]
            }
          ]
        }
      }
    ]
  }
};

export default missing;