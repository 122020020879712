import rest from '../../app.services/api/rest.mjs';

export default class HTMLOAppVersion extends HTMLElement {
  constructor() {
    super();

    this.rest = rest;

    if (!Object.prototype.hasOwnProperty.call(this, 'platform')) {
      /**
       * Name of the platform to retrieve.
       * @type {string}
       */
      this.platform = this.getAttribute('platform');
    }

    if (!Object.prototype.hasOwnProperty.call(this, 'os')) {
      /**
       * Name of the os to retrieve.
       * @type {string}
       */
      this.os = this.getAttribute('os');
    }
  }

  async getVersion() {
    const result = await this.rest.getVersion(this.platform?.toLowerCase(), this.os?.toLowerCase());

    if (result?.length) {
      this.innerHTML = `Version: ${result[0]?.version}`;
    } else {
      this.innerHTML = `Currently Unavailable`;
    }
  }

  connectedCallback() {
    this.innerHTML = `Version: `;
    this.classList.add('badge');

    this.getVersion();
  }
}

window.customElements.define('o-app-version', HTMLOAppVersion);