/**
 * @typedef {toolList} ToolList
 */
const toolList = {
  platform_list: [
    { name: '-', value: undefined },
    { name: 'All Platforms', value: 'ALL' },
    { name: 'Web', value: 'WEB' },
    { name: 'macOS', value: 'MACOS' },
    { name: 'Windows', value: 'WINDOWS' },
    { name: 'iOS', value: 'IOS' },
    { name: 'Android', value: 'ANDROID' }
  ],
  topic_list: [
    { name: '-', value: undefined },
    { name: 'Support', value: 'SUPPORT' },
    { name: 'Bug Report', value: 'BUG' },
    { name: 'Sales', value: 'SALES' },
    { name: 'General Inquiry', value: 'QUESTION' }
  ],
};

export default toolList;